import { useState, useEffect } from "react";
import chartIcon from "../../assets/home/chart-icon.svg";
import SimpleBarChart from "../../components/BarChart";
import Section from "../../components/BrandComparisonSection";
import CompareBarChart from "../../components/CompareBarChart";
import styles from "../../styles/pages/sleep/habits.module.css";
import fetchSurveyData from "../../services/index";
import noDataFound from "../../assets/home/ProductsNoDataFound.svg"

const FitnessData = require("../../data/fitnessData.json");

const FitnessAndSkin = ({selectedFilters}) => {
  const [skinConcernData, setSkinConcernData] = useState([]);
  const [skinConditionData, setSkinConditionData] = useState([]);
  const [bodySkinConcernData, setBodySkinConcernData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const responseData = await fetchSurveyData("A3n7BzDW", selectedFilters);
        const processData = (attributeName, dataKey, limit) => {
          const counts = {};
          responseData.forEach((entry) => {
            const value = entry[dataKey];
            if (value && value !== "") {
              const values = value.split(/,\s*(?![^()]*\))/);
              values.forEach((v) => {
                const trimmedValue = v.trim();
                if (trimmedValue !== "") {
                  if (counts[trimmedValue]) {
                    counts[trimmedValue]++;
                  } else {
                    counts[trimmedValue] = 1;
                  }
                }
              });
            }
          });

          const dataArray = Object.entries(counts)
            .map(([name, value]) => ({ name: name || "Unknown", value }))
            .filter((entry) => entry.name !== "");

          const sortedArray = dataArray
            .sort((a, b) => b.value - a.value)
            .slice(0, limit);

          switch (attributeName) {
            case "skinConcern":
              setSkinConcernData(sortedArray);
              break;
            case "skinCondition":
              setSkinConditionData(sortedArray);
              break;
            case "bodySkinConcern":
              setBodySkinConcernData(sortedArray);
              break;
            default:
              break;
          }
        };

        processData("skinConcern", "skin_concern", 9);
        processData("skinCondition", "skin_condition", 6);
        processData("bodySkinConcern", "body_skin_concern", 5);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, [selectedFilters]);

  return (
    <div style={{ width: "100%" }}>
      <div className={styles.brandsSelectContainer}>
        <Section
          width={50}
          heading={
            <div className={styles.heading}>
              <img src={chartIcon} alt="" />
              <p>Skin conditions</p>
            </div>
          }
          content={
            skinConditionData.length > 0 ? (
              <CompareBarChart data={skinConditionData} yAxisWidth={20}
              showLegend={false}/>
            ) : (
              <div><img src={noDataFound} alt=""  style={{marginLeft: "25%"}} /></div>
            )
          }
        />

        <Section
          width={50}
          heading={
            <div className={styles.heading}>
              <img src={chartIcon} alt="" />
              <p>Body concerns</p>
            </div>
          }
          content={
            bodySkinConcernData.length > 0 ? (
              <SimpleBarChart data={bodySkinConcernData} />
            ) : (
              <div><img src={noDataFound} alt=""  style={{marginLeft: "25%"}} /></div>
            )
          }
        />
      </div>
      <div className={styles.brandsSelectContainer}>
        <Section
          width={100}
          heading={
            <div className={styles.heading}>
              <img src={chartIcon} alt="" />
              <p>Skin concerns</p>
            </div>
          }
          content={
            skinConcernData.length > 0 ? (
              <CompareBarChart data={skinConcernData} yAxisWidth={20}
              showLegend={false}
              slantXAxisLabels={true}/>
            ) : (
              <div><img src={noDataFound} alt=""  style={{marginLeft: "35%"}} /></div>
            )
          }
        />
      </div>{" "}
    </div>
  );
};

export default FitnessAndSkin;
