import { useEffect, useState } from "react";
import chartIcon from "../../assets/demographics/chart-icon.svg";
import doughnutIcon from "../../assets/wellnessCommon/doughnutIcon.svg";
import SimpleBarChart from "../../components/BarChart";
import Section from "../../components/BrandComparisonSection";
import CustomDonutChart from "../../components/doughnutChart";
import fetchSurveyData from "../../services";
import noDataFound from "../../assets/home/ProductsNoDataFound.svg"
import styles from "../../styles/pages/sleep/habits.module.css";

const SleepAndSkinData = require("../../data/sleepData.json");

const SkinAndSleep = ({ selectedFilters }) => {
  const [sleepImpactData, setSleepImpactData] = useState([]);
  const [bodyConcernsData, setBodyConcernsData] = useState([]);
  const [skinConditionData, setSkinConditionData] = useState([]);
  const [skinConcernsData, setSkinConcernsData] = useState([]);

  const fetchFormData = async () => {
    try {
      const responseData = await fetchSurveyData("Tj8wToIm", selectedFilters);
    const processData = (attributeName, dataKey, limit) => {
      const counts = {};
      responseData.forEach((entry) => {
        const value = entry[dataKey];
        if (value && value !== "") {
          const values = value.split(/,\s*(?![^()]*\))/);
          values.forEach((v) => {
            const trimmedValue = v.trim();
            if (trimmedValue !== "") {
              if (counts[trimmedValue]) {
                counts[trimmedValue]++;
              } else {
                counts[trimmedValue] = 1;
              }
            }
          });
        }
      });

      const dataArray = Object.entries(counts)
        .map(([name, value]) => ({ name: name || "Unknown", value }))
        .filter((entry) => entry.name !== "");

      const sortedArray = dataArray
        .sort((a, b) => b.value - a.value)
        .slice(0, limit);

      switch (attributeName) {
        case "bodyConcerns":
          setBodyConcernsData(sortedArray);
          break;
        case "skinCondition":
          setSkinConditionData(sortedArray);
          break;
        case "skinConcerns":
          setSkinConcernsData(sortedArray);
          break;
        default:
          break;
      }
    };
    processData("bodyConcerns", "skincare_concerns", 4);
    processData("skinCondition", "skin_condition", 4);
    processData("skinConcerns", "skin_concerns", 4);
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};
fetchFormData();

  useEffect(() => {
    const processSleepImpactData = async () => {
      try {
        const responseData = await fetchSurveyData("Tj8wToIm", selectedFilters);
      const sleepImpactCounts = {
        Yes: { value: 0, fill: "#747ED4" },
        No: { value: 0, fill: "#22C55E" },
        Sometimes: { value: 0, fill: "#FCF36B" },
      };

      responseData.forEach((entry) => {
        const { concerns_impacting_sleep } = entry;
        if (concerns_impacting_sleep && concerns_impacting_sleep !== "") {
          if (sleepImpactCounts[concerns_impacting_sleep]) {
            sleepImpactCounts[concerns_impacting_sleep].value++;
          }
        }
      });

      const sleepImpactArray = Object.entries(sleepImpactCounts)
        .map(([name, { value, fill }]) => ({
          name: name || "Unknown",
          value,
          fill,
        }))
        .filter((entry) => entry.name !== "");

      const sortedSleepImpactArray = sleepImpactArray.sort(
        (a, b) => b.value - a.value
      );

      setSleepImpactData(sortedSleepImpactArray);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  processSleepImpactData();
}, [selectedFilters]);

  return (
    <div style={{ width: "100%" }}>
      <div className={styles.brandsSelectContainer}>
        <Section
          width={50}
          heading={
            <div className={styles.heading}>
              <img src={doughnutIcon} alt="" />
              <p>
                Do you feel your skin concerns or conditions are impacting your
                sleep?
              </p>
            </div>
          }
          content={
            sleepImpactData.length > 0 ? (
              <div
                style={{
                  display: "flex",
                  gap: "10px",
                }}
              >
                <div style={{ width: "65%", marginTop: "40px" }}>
                  <CustomDonutChart propData={sleepImpactData} />
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    // flexWrap: "wrap",
                    gap: "10px",
                    width: "60%",
                    marginTop: "30px",
                  }}
                >
                  {sleepImpactData.map((item, index) => (
                    <div
                      key={index}
                      style={{
                        height: "50px",
                        width: "80%",
                        border: "1px solid #F2F2F2",
                        display: "flex",
                        flexDirection: "row",
                        gap: "10px",
                        padding: "6px",
                        borderRadius: "8px",
                      }}
                    >
                      <div
                        style={{
                          height: "16px",
                          width: "16px",
                          borderRadius: "50%",
                          backgroundColor: `${item.fill}`,
                          gap: "10px",
                        }}
                      ></div>
                      <div>{item.title || item.name}</div>
                    </div>
                  ))}
                </div>
              </div>
            ) : (
              <div><img src={noDataFound} alt=""  style={{marginLeft: "130px"}} /></div>
            )
          }
        />

        <Section
          width={50}
          heading={
            <div className={styles.heading}>
              <img src={chartIcon} alt="" />
              <p>Body concerns</p>
            </div>
          }
          content={
            bodyConcernsData.length > 0 ? (
              <SimpleBarChart data={bodyConcernsData} />
            ) : (
              <div><img src={noDataFound} alt=""  style={{marginLeft: "120px"}} /></div>
            )
          }
        />
      </div>
      <div className={styles.brandsSelectContainer}>
        <Section
          width={65}
          heading={
            <div className={styles.heading}>
              <img src={chartIcon} alt="" />
              <p>Skin Conditions</p>
            </div>
          }
          content={
            skinConditionData.length > 0 ? (
              <SimpleBarChart data={skinConditionData} />
            ) : (
              <div><img src={noDataFound} alt=""  style={{marginLeft: "230px"}} /></div>
            )
          }
        />

        <Section
          width={35}
          heading={
            <div className={styles.heading}>
              <img src={chartIcon} alt="" />
              <p>Skin Concerns</p>
            </div>
          }
          content={
            skinConcernsData.length > 0 ? (
              <SimpleBarChart data={skinConcernsData} />
            ) : (
              <div><img src={noDataFound} alt=""  style={{marginLeft: "40px"}} /></div>
            )
          }
        />
      </div>{" "}
    </div>
  );
};

export default SkinAndSleep;
