import chartIcon from "../../assets/home/chart-icon.svg";
import genderIcon from "../../assets/home/gender-icon.svg";
import SimpleBarChart from "../../components/BarChart";
import Section from "../../components/BrandComparisonSection";
import CompareBarChart from "../../components/CompareBarChart";
import SemiChart from "../../components/SemiDouughnutChart";
import fetchSurveyData from "../../services/index";
import styles from "../../styles/pages/sleep/habits.module.css";
import { useState, useEffect } from "react";
import noDataFound from "../../assets/home/ProductsNoDataFound.svg"

const menstrualCareData = require("../../data/menstrualCare.json");

const MenstruationAndSkin = ({selectedFilters}) => {
  const [productData, setProductData] = useState([]);
  const [periodFlowData, setPeriodFlowData] = useState([]);
  const [skinConcernsData, setSkinConcernsData] = useState([]);
  const [skinSensitivityData, setSkinSensitivityData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const responseData = await fetchSurveyData("YaxRlAet", selectedFilters);
        const processProductData = () => {
          const productCounts = {};
          responseData.forEach((entry) => {
            const { preferred_period_product } = entry;
            if (preferred_period_product) {
              if (productCounts[preferred_period_product]) {
                productCounts[preferred_period_product]++;
              } else {
                productCounts[preferred_period_product] = 1;
              }
            }
          });
          const productArray = Object.entries(productCounts).map(
            ([name, value]) => ({
              name,
              value,
              fill: name === "Disposable" ? "#747ED4" : "#FCF36B",
            })
          );

          const sortedProductArray = productArray.sort(
            (a, b) => b.value - a.value
          );
          setProductData(sortedProductArray);
        };

        const processPeriodFlowData = () => {
          const periodFlowCounts = {};
          responseData.forEach((entry) => {
            const { period_flow } = entry;
            if (period_flow && period_flow !== "") {
              const spendValues = period_flow.split(/,\s*(?![^()]*\))/);
              spendValues.forEach((value) => {
                const trimmedValue = value.trim();
                if (trimmedValue !== "") {
                  if (periodFlowCounts[trimmedValue]) {
                    periodFlowCounts[trimmedValue]++;
                  } else {
                    periodFlowCounts[trimmedValue] = 1;
                  }
                }
              });
            }
          });

          const periodFlowArray = Object.entries(periodFlowCounts)
            .map(([name, value]) => ({ name: name || "Unknown", value }))
            .filter((entry) => entry.name !== "");

          const sortedPeriodFlowArray = periodFlowArray
            .sort((a, b) => b.value - a.value)
            .slice(0, 4);

          setPeriodFlowData(sortedPeriodFlowArray);
        };

        const processSkinConcernData = () => {
          const skinConcernCounts = {};
          responseData.forEach((entry) => {
            const { skin_concerns } = entry;
            if (skin_concerns && skin_concerns !== "") {
              const concernValue = skin_concerns.split(/,\s*(?![^()]*\))/);
              concernValue.forEach((value) => {
                const trimmedValue = value.trim();
                if (trimmedValue !== "") {
                  if (skinConcernCounts[trimmedValue]) {
                    skinConcernCounts[trimmedValue]++;
                  } else {
                    skinConcernCounts[trimmedValue] = 1;
                  }
                }
              });
            }
          });

          const skinConcernArray = Object.entries(skinConcernCounts)
            .map(([name, value]) => ({ name: name || "Unknown", value }))
            .filter((entry) => entry.name !== "");

          const sortedSkinConcernArray = skinConcernArray
            .sort((a, b) => b.value - a.value)
            .slice(0, 4);

          setSkinConcernsData(sortedSkinConcernArray);
        };

        const processSkinSensitivityData = () => {
          const skinSensitivityCounts = {};
          responseData.forEach((entry) => {
            const { skin_sensitivity } = entry;
            if (skin_sensitivity && skin_sensitivity !== "") {
              const concernValue = skin_sensitivity.split(/,\s*(?![^()]*\))/);
              concernValue.forEach((value) => {
                const trimmedValue = value.trim();
                if (trimmedValue !== "") {
                  if (skinSensitivityCounts[trimmedValue]) {
                    skinSensitivityCounts[trimmedValue]++;
                  } else {
                    skinSensitivityCounts[trimmedValue] = 1;
                  }
                }
              });
            }
          });

          const skinSensitivityArray = Object.entries(skinSensitivityCounts)
            .map(([name, value]) => ({ name: name || "Unknown", value }))
            .filter((entry) => entry.name !== "");

          const sortedSkinSensitivityArray = skinSensitivityArray
            .sort((a, b) => b.value - a.value)
            .slice(0, 4);

          setSkinSensitivityData(sortedSkinSensitivityArray);
        };

        processProductData();
        processPeriodFlowData();
        processSkinConcernData();
        processSkinSensitivityData();
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [selectedFilters]);

  return (
    <div style={{ width: "100%" }}>
      <div className={styles.brandsSelectContainer}>
        <Section
          width={50}
          heading={
            <div className={styles.heading}>
              <img src={genderIcon} alt="" />
              <p>Do you prefer reusable or disposable period products?</p>
            </div>
          }
          content={
            productData.length > 0 ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  paddingBottom: "10px",
                  marginTop: "20px",
                }}
              >
                <SemiChart data={productData} label="Women 99%" />
                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    gap: "30px",
                  }}
                >
                  {productData.map((item, index) => (
                    <div
                      key={index}
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "10px",
                        justifyContent: "center",
                        alignItems: "center",
                        marginTop: "30px",
                      }}
                    >
                      <div
                        style={{
                          height: "16px",
                          width: "16px",
                          borderRadius: "50%",
                          backgroundColor: `${item.fill}`,
                        }}
                      ></div>
                      <div>{item.title || item.name}</div>
                    </div>
                  ))}
                </div>
              </div>
            ) : (
              <div><img src={noDataFound} alt=""  style={{marginLeft: "130px"}} /></div>
            )
          }
        />

        <Section
          width={50}
          heading={
            <div className={styles.heading}>
              <img src={chartIcon} alt="" />
              <p>How heavy is your period flow?</p>
            </div>
          }
          content={
            periodFlowData.length > 0 ? (
              <SimpleBarChart data={periodFlowData} />
            ) : (
              <div><img src={noDataFound} alt=""  style={{marginLeft: "130px"}} /></div>
            )
          }
        />
      </div>
      <div className={styles.brandsSelectContainer}>
        <Section
          width={50}
          heading={
            <div className={styles.heading}>
              <img src={chartIcon} alt="" />
              <p>What are your skin concerns</p>
            </div>
          }
          content={
            skinConcernsData.length > 0 ? (
              <CompareBarChart data={skinConcernsData}
              yAxisWidth={20}
              showLegend={false}
              slantXAxisLabels={true} />
            ) : (
              <div><img src={noDataFound} alt=""  style={{marginLeft: "130px"}} /></div>
            )
          }
        />

        <Section
          width={50}
          heading={
            <div className={styles.heading}>
              <img src={chartIcon} alt="" />
              <p>Do you feel your skin is more sensitive during your period?</p>
            </div>
          }
          content={
            skinSensitivityData.length > 0 ? (
              <CompareBarChart data={skinSensitivityData}
              yAxisWidth={20}
              showLegend={false}/>
            ) : (
              <div><img src={noDataFound} alt=""  style={{marginLeft: "130px"}} /></div>
            )
          }
        />
      </div>{" "}
    </div>
  );
};

export default MenstruationAndSkin;
