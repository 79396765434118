import styles from "../styles/components/MultiFilter.module.css";
import miniFilterIcon from "../assets/home/mini-filter-icon.svg";
import { useState } from "react";
import { Space, Button } from "antd";
const MultiFilter = ({ handleUpdateFilter }) => {
  // const handleUpdateFilter = (filters) => {
  //   setSelectedFilters(filters);
  // };

  const [open, setOpen] = useState(false);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const [selectedFilters, setSelectedFilters] = useState({
    ageFilter: [],
    genderFilter: [],
    skinTypeFilter: [],
    cityFilter: [],
    // hairTypeFilter: [],
  });
  const handleFilterToggle = (filterType, filterValue) => {
    const newSelectedFilters = { ...selectedFilters };

    const filterIndex = newSelectedFilters[filterType].indexOf(filterValue);

    if (filterIndex !== -1) {
      newSelectedFilters[filterType].splice(filterIndex, 1);
    } else {
      newSelectedFilters[filterType].push(filterValue);
    }

    setSelectedFilters(newSelectedFilters);
    handleUpdateFilter(selectedFilters);
  };

  const renderFilterCapsules = (filterType, filterOptions) => {
    return filterOptions.map((filter) => (
      <p
        key={filter}
        className={`${styles.filterCapsule} ${
          selectedFilters[filterType].includes(filter)
            ? styles.activeFilter
            : ""
        }`}
        onClick={() => handleFilterToggle(filterType, filter)}
      >
        {filter}
      </p>
    ));
  };
  return (
    <div
      style={{
        backgroundColor: "#ffffff",
        padding: "15px",
        zIndex: 99,
        display: "flex",
        flexWrap: "wrap",
        transition: "right 0.4s ease-in-out",
      }}
      className={styles.container}
    >

      <div>
        <div>
          <p className={styles.filterTitle}>Age</p>
          <div style={{ display: "flex", flexWrap: "wrap", gap: "8px" }}>
            {renderFilterCapsules("ageFilter", [
              "Less than 20 years old",
              "21-25 years old",
              "26-35 years old",
              "36-50 years old",
              "Over 50 years old",
            ])}
          </div>
        </div>

        <div>
          <p className={styles.filterTitle}>Gender</p>
          <div style={{ display: "flex", flexWrap: "wrap", gap: "8px" }}>
            {renderFilterCapsules("genderFilter", ["Female", "Male", "Other"])}
          </div>
        </div>

        <div>
          <p className={styles.filterTitle}>Skin Type</p>
          <div style={{ display: "flex", flexWrap: "wrap", gap: "8px" }}>
            {renderFilterCapsules("skinTypeFilter", [
              "Combination",
              "Dry",
              "Oily",
              "I don't know",
            ])}
          </div>
        </div>

        {/* <div>
          <p className={styles.filterTitle}>Hair Type</p>
          <div style={{ display: "flex", flexWrap: "wrap", gap: "8px" }}>
            {renderFilterCapsules("hairTypeFilter", [
              "Straight",
              "Wavy",
              "Curly",
              "I don't know",
            ])}
          </div>
        </div> */}
      </div>

      <div>
        <p className={styles.filterTitle}>Location</p>
        <div style={{ display: "flex", flexWrap: "wrap", gap: "8px" }}>
          <p
            className={`${styles.filterCapsule} ${
              selectedFilters.cityFilter.includes("Metropolitan")
                ? styles.activeFilter
                : ""
            }`}
            onClick={() => handleFilterToggle("cityFilter", "Metropolitan")}
          >
            Metropolitan
          </p>
          <p
            className={`${styles.filterCapsule} ${
              selectedFilters.cityFilter.includes("Non-Metropolitan")
                ? styles.activeFilter
                : ""
            }`}
            onClick={() => handleFilterToggle("cityFilter", "Non-Metropolitan")}
          >
            Non-Metropolitan
          </p>
        </div>
      </div>

      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "flex-end",
          gap: "24px",
          alignItems: "center",
          marginTop: "24px",
        }}
      >
        <div
          style={{
            fontWeight: 400,
            fontSize: "14px",
            lineHeight: "21px",
            color: "#52525B",
          }}
        >
          {
            [
              ...selectedFilters.ageFilter,
              ...selectedFilters.genderFilter,
              ...selectedFilters.skinTypeFilter,
              ...selectedFilters.cityFilter,
            ].length
          }{" "}
          Filters
        </div>
        {/* <div> */}
          <Space>
            {/* <Button onClick={onClose}>Close</Button> */}
            <Button type="primary">Apply Filter</Button>
          </Space>
        {/* </div> */}
      </div>
    </div>
  );
};

export default MultiFilter;