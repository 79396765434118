import { useState, useEffect } from "react";
import chartIcon from "../../assets/demographics/chart-icon.svg";
import doughnutIcon from "../../assets/wellnessCommon/doughnutIcon.svg";
import Section from "../../components/BrandComparisonSection";
import CompareBarChart from "../../components/CompareBarChart";
import SemiChart from "../../components/SemiDouughnutChart";
import styles from "../../styles/pages/sleep/habits.module.css";
import fetchSurveyData from "../../services";
import noDataFound from "../../assets/home/ProductsNoDataFound.svg"

const SleepAndSkinData = require("../../data/sleepData.json");

const Activities = ({ selectedFilters }) => {
  const [index, setIndex] = useState(0);
  const [page, setPage] = useState(1);
  const handleChange = (e) => {
    setIndex(Number(e) - 1);
  };

  const [showerBeforeSleepData, setShowerBeforeSleepData] = useState([]);
  const [caffeineBeforeSleepData, setCaffeineBeforeSleepData] = useState([]);
  const [melatoninFrequencyData, setMelatoninFrequencyData] = useState([]);
  const [melatoninIntakeData, setMelatoninIntakeData] = useState([]);
  const [foodBeforeSleepData, setFoodBeforeSleepData] = useState([]);
  const [activityBeforeSleepData, setActivityBeforeSleepData] = useState([]);

  const fetchFormData = async () => {
    try {
      const responseData = await fetchSurveyData("Tj8wToIm", selectedFilters);
    const processData = (attributeName, dataKey, limit) => {
      const counts = {};
      responseData.forEach((entry) => {
        const value = entry[dataKey];
        if (value && value !== "") {
          const values = value.split(/,\s*(?![^()]*\))/);
          values.forEach((v) => {
            const trimmedValue = v.trim();
            if (trimmedValue !== "") {
              if (counts[trimmedValue]) {
                counts[trimmedValue]++;
              } else {
                counts[trimmedValue] = 1;
              }
            }
          });
        }
      });

      const dataArray = Object.entries(counts)
        .map(([name, value]) => ({ name: name || "Unknown", value }))
        .filter((entry) => entry.name !== "");

      const sortedArray = dataArray
        .sort((a, b) => b.value - a.value)
        .slice(0, limit);

      const defaultColors = ["#FCF36B", "#747ED4", "#3F3F46", "#22C55E"];

      const dataWithFill = sortedArray.map((item, index) => ({
        ...item,
        fill: defaultColors[index] || "#CCCCCC",
      }));

      switch (attributeName) {
        case "showerBeforeSleep":
          setShowerBeforeSleepData(dataWithFill);
          break;
        case "caffeineBeforeSleep":
          setCaffeineBeforeSleepData(dataWithFill);
          break;
        case "melatoninFrequency":
          setMelatoninFrequencyData(dataWithFill);
          break;
        case "melatoninIntake":
          setMelatoninIntakeData(sortedArray);
          break;
        case "foodBeforeSleep":
          setFoodBeforeSleepData(sortedArray);
          break;
        case "activityBeforeSleep":
          setActivityBeforeSleepData(sortedArray);
          break;
        default:
          break;
      }
    };

    processData("showerBeforeSleep", "shower_before_sleep", 4);
    processData("caffeineBeforeSleep", "caffeine_before_sleep", 4);
    processData("melatoninFrequency", "melatonin_frequency", 4);
    processData("melatoninIntake", "melatonin_intake", 4);
    processData("foodBeforeSleep", "food_before_sleep", 3);
    processData("activityBeforeSleep", "activity_before_sleep", 4);
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};
fetchFormData();

  return (
    <div style={{ width: "100%" }}>
      <div className={styles.brandsSelectContainer}>
        <Section
          width={25}
          heading={
            <div className={styles.heading}>
              <img src={doughnutIcon} alt="" />
              <p>Do you shower before sleeping?</p>
            </div>
          }
          content={
            showerBeforeSleepData.length > 0 ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  paddingBottom: "10px",
                  marginTop: "20px",
                }}
              >
                <SemiChart data={showerBeforeSleepData} label="Women 99%" />
                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    gap: "30px",
                  }}
                >
                  {showerBeforeSleepData.map((item, index) => (
                    <div
                      key={index}
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "10px",
                        justifyContent: "center",
                        alignItems: "center",
                        marginTop: "30px",
                      }}
                    >
                      <div
                        style={{
                          height: "16px",
                          width: "16px",
                          borderRadius: "50%",
                          backgroundColor: `${item.fill}`,
                        }}
                      ></div>
                      <div>{item.title || item.name}</div>
                    </div>
                  ))}
                </div>
              </div>
            ) : (
              <div><img src={noDataFound} alt=""  style={{marginLeft: "-20px"}} /></div>
            )
          }
        />

        <Section
          width={50}
          heading={
            <div className={styles.heading}>
              <img src={chartIcon} alt="" />
              <p>How often do you take melatonin?</p>
            </div>
          }
          content={
            melatoninIntakeData.length > 0 ? (
              <CompareBarChart data={melatoninIntakeData}  yAxisWidth={20}
              showLegend={false}/>
            ) : (
              <div><img src={noDataFound} alt=""  style={{marginLeft: "120px"}} /></div>
            )
          }
        />
        <Section
          width={25}
          heading={
            <div className={styles.heading}>
              <img src={doughnutIcon} alt="" />
              <p>Do you consume caffeine close to your bedtime?</p>
            </div>
          }
          content={
            caffeineBeforeSleepData.length > 0 ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  paddingBottom: "10px",
                  marginTop: "20px",
                }}
              >
                <SemiChart data={caffeineBeforeSleepData} label="Women 99%" />
                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    gap: "30px",
                  }}
                >
                  {caffeineBeforeSleepData.map((item, index) => (
                    <div
                      key={index}
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "10px",
                        justifyContent: "center",
                        alignItems: "center",
                        marginTop: "30px"
                      }}
                    >
                      <div
                        style={{
                          height: "16px",
                          width: "16px",
                          borderRadius: "50%",
                          backgroundColor: `${item.fill}`,
                        }}
                      ></div>
                      <div>{item.title || item.name}</div>
                    </div>
                  ))}
                </div>
              </div>
            ) : (
              <div><img src={noDataFound} alt=""  style={{marginLeft: "-20px"}} /></div>
            )
          }
        />
      </div>
      <div className={styles.brandsSelectContainer}>
        <Section
          width={30}
          heading={
            <div className={styles.heading}>
              <img src={chartIcon} alt="" />
              <p>Do you consume food 1-2hrs before sleeping?</p>
            </div>
          }
          content={
            foodBeforeSleepData.length > 0 ? (
              <CompareBarChart data={foodBeforeSleepData}  yAxisWidth={20}
              showLegend={false}/>
            ) : (
              <div><img src={noDataFound} alt=""  style={{marginLeft: "20px"}} /></div>
            )
          }
        />
        <Section
          width={25}
          heading={
            <div className={styles.heading}>
              <img src={doughnutIcon} alt="" />
              <p>Do you take melatonin regularly to fall asleep?</p>
            </div>
          }
          content={
            melatoninFrequencyData.length > 0 ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  paddingBottom: "10px",
                  marginTop: "10px",
                }}
              >
                <SemiChart data={melatoninFrequencyData} label="Women 99%" />
                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    gap: "10px",
                  }}
                >
                  {melatoninFrequencyData.map((item, index) => (
                    <div
                      key={index}
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "10px",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          height: "16px",
                          width: "16px",
                          borderRadius: "50%",
                          marginLeft: "15px",
                          backgroundColor: `${item.fill}`,
                        }}
                      ></div>
                      <div>{item.title || item.name}</div>
                    </div>
                  ))}
                </div>
              </div>
            ) : (
              <div><img src={noDataFound} alt=""  style={{marginLeft: "-20px"}} /></div>
            )
          }
        />
        <Section
          width={45}
          heading={
            <div className={styles.heading}>
              <img src={chartIcon} alt="" />
              <p>Last thing before sleep</p>
            </div>
          }
          content={
            activityBeforeSleepData.length > 0 ? (
              <CompareBarChart data={activityBeforeSleepData}  yAxisWidth={20}
              showLegend={false}/>
            ) : (
              <div><img src={noDataFound} alt=""  style={{marginLeft: "90px"}} /></div>
            )
          }
        />
      </div>{" "}
    </div>
  );
};

export default Activities;
