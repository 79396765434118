// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Urbanist", "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* padding: 0px 24px; */
  background-color: #efeeee;
}
* {
  box-sizing: border-box;
}

body::-webkit-scrollbar {
  display: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", Urbanist
    monospace;
}

/* .background-stuff {
  background: linear-gradient(to right, #ffffff 1px, transparent 1px) 0 / 120px
      repeat-x,
    #18181B;
} */
.background-stuff {
  background: linear-gradient(to right, #ffffff 0.3%, transparent 0.4%) 0 /
      120px repeat-x,
    #18181b;
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT;;gCAE8B;EAC9B,mCAAmC;EACnC,kCAAkC;EAClC,uBAAuB;EACvB,yBAAyB;AAC3B;AACA;EACE,sBAAsB;AACxB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE;aACW;AACb;;AAEA;;;;GAIG;AACH;EACE;;WAES;AACX","sourcesContent":["body {\n  margin: 0;\n  font-family: -apple-system, BlinkMacSystemFont, \"Urbanist\", \"Segoe UI\",\n    \"Roboto\", \"Oxygen\", \"Ubuntu\", \"Cantarell\", \"Fira Sans\", \"Droid Sans\",\n    \"Helvetica Neue\", sans-serif;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n  /* padding: 0px 24px; */\n  background-color: #efeeee;\n}\n* {\n  box-sizing: border-box;\n}\n\nbody::-webkit-scrollbar {\n  display: none;\n}\n\ncode {\n  font-family: source-code-pro, Menlo, Monaco, Consolas, \"Courier New\", Urbanist\n    monospace;\n}\n\n/* .background-stuff {\n  background: linear-gradient(to right, #ffffff 1px, transparent 1px) 0 / 120px\n      repeat-x,\n    #18181B;\n} */\n.background-stuff {\n  background: linear-gradient(to right, #ffffff 0.3%, transparent 0.4%) 0 /\n      120px repeat-x,\n    #18181b;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
