import { Menu } from "antd";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import newLogo from "../assets/wellnessCommon/WellnessLogo.svg";
// import GutHealthIcon from "../assets/wellnessCommon/gutHealthIcon.svg";
import MenstrualCareIcon from "../assets/dynamicSvgs/menstrualCareLogo";
import SleepIcon from "../assets/dynamicSvgs/sleepLogo";
import GutHealthIcon from "../assets/dynamicSvgs/gutHealthIcon";
import MentalHealthIcon from "../assets/dynamicSvgs/mentalHealthIcon";
import FitnessIcon from "../assets/dynamicSvgs/fitnessIcon";
import styles from "../styles/components/common/Sidebar.module.css";

const Sidebar = ({ handleChange }) => {
  const { SubMenu } = Menu;
  const location = useLocation();
  const navigate = useNavigate();

  const [selectedKey, setSelectedKey] = useState(null);
  const [active, setActive] = useState(null);

  const handleClick = (e) => {
    handleChange(e);
    setSelectedKey(e);

    switch (e) {
      case "1":
        navigate("/");
        setActive(1);
        break;
      case "2":
        navigate("/gut-health");
        setActive(2);
        break;
      case "3":
        navigate("/mental-health");
        setActive(3);
        break;
      case "4":
        navigate("/fitness");
        setActive(4);
        break;
      case "5":
        navigate("/menstrual-care");
        setActive(5);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    // Set active based on current pathname
    const pathname = location.pathname;
    switch (pathname) {
      case "/":
        setActive(1);
        setSelectedKey("1");
        break;
      case "/gut-health":
        setActive(2);
        setSelectedKey("2");
        break;
      case "/mental-health":
        setActive(3);
        setSelectedKey("3");
        break;
      case "/fitness":
        setActive(4);
        setSelectedKey("4");
        break;
      case "/menstrual-care":
        setActive(5);
        setSelectedKey("5");
        break;
      default:
        break;
    }
  }, [location.pathname]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        backgroundColor: "#FFF",
        alignItems: "start",
        position: "fixed",
        zIndex: 3,
        top: 0,
        padding: "24px 0px 0px 0px",
        width: "280px",
        height: "100vh",
        fontFamily: "Urbanist",
      }}
      className="hide-in__mobile"
    >
      <img
        alt=""
        height={45}
        style={{ marginLeft: "25px", marginBottom: "20px" }}
        src={newLogo}
        onClick={() => handleClick("1")}
      />
      <Menu
        mode="inline"
        style={{ width: 256 }}
        className={styles.sidebarMenu}
        selectedKeys={[selectedKey]}
      >
        <Menu.Item
          key="1"
          onClick={() => handleClick("1")}
          className={`${styles.menuItem} ${
            selectedKey === "1" ? styles.selectedMenuItem : ""
          }`}
        >
          <div className={styles.menuItemTitle}>
            <SleepIcon selected={selectedKey === "1"} />
            <span
              className={`${styles.menuItemTitleBold} ${
                selectedKey === "1" ? styles.selectedMenuItem : ""
              }`}
            >
              Sleep
            </span>
          </div>
        </Menu.Item>
        <Menu.Item
          key="2"
          onClick={() => handleClick("2")}
          className={`${styles.menuItem} ${
            selectedKey === "2" ? styles.selectedMenuItem : ""
          }`}
        >
          <div className={styles.menuItemTitle}>
          <GutHealthIcon selected={selectedKey === "2"} />
            <span
              className={`${styles.menuItemTitleBold} ${
                selectedKey === "2" ? styles.selectedMenuItem : ""
              }`}
            >
              Gut Health
            </span>
          </div>
        </Menu.Item>
        <Menu.Item
          key="3"
          onClick={() => handleClick("3")}
          className={`${styles.menuItem} ${
            selectedKey === "3" ? styles.selectedMenuItem : ""
          }`}
        >
          <div className={styles.menuItemTitle}>
          <MentalHealthIcon selected={selectedKey === "3"} />
            <span
              className={`${styles.menuItemTitleBold} ${
                selectedKey === "3" ? styles.selectedMenuItem : ""
              }`}
            >
              Mental Health
            </span>
          </div>
        </Menu.Item>
        <Menu.Item
          key="4"
          onClick={() => handleClick("4")}
          className={`${styles.menuItem} ${
            selectedKey === "4" ? styles.selectedMenuItem : ""
          }`}
        >
          <div className={styles.menuItemTitle}>
          <FitnessIcon selected={selectedKey === "4"} />
            <span
              className={`${styles.menuItemTitleBold} ${
                selectedKey === "4" ? styles.selectedMenuItem : ""
              }`}
            >
              Fitness
            </span>
          </div>
        </Menu.Item>
        <Menu.Item
          key="5"
          onClick={() => handleClick("5")}
          className={`${styles.menuItem} ${
            selectedKey === "5" ? styles.selectedMenuItem : ""
          }`}
        >
          <div className={styles.menuItemTitle}>
            <MenstrualCareIcon selected={selectedKey === "5"} />
            <span
              className={`${styles.menuItemTitleBold} ${
                selectedKey === "5" ? styles.selectedMenuItem : ""
              }`}
            >
              Menstrual Care
            </span>
          </div>
        </Menu.Item>
      </Menu>
    </div>
  );
};

export default Sidebar;
