import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div
        style={{
          backgroundColor: "#fff",
          padding: "5px",
          border: "1px solid #ccc",
        }}
      >
        <p>{label}</p>
        <ul>
          {payload.map((entry, index) => (
            <li key={index} style={{ color: entry.color }}>
              {`${entry.name}: ${entry.value}`}
            </li>
          ))}
        </ul>
      </div>
    );
  }

  return null;
};

const CustomLegend = (props) => {
  const { payload } = props;

  return (
    <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
      {payload.map((entry, index) => (
        <div
          key={`legend-${index}`}
          style={{
            display: "flex",
            alignItems: "center",
            marginTop: 8,
            border: "0.8px solid lightgrey",
            padding: "2px 8px",
            borderRadius: "4px",
            marginLeft: "10px",
          }}
        >
          <div
            style={{
              width: 10,
              height: 10,
              backgroundColor: entry.color,
              borderRadius: "50%",
              marginRight: 5,
            }}
          />
          <span style={{ fontSize: 14, marginRight: 5 }}>{entry.value}</span>
        </div>
      ))}
    </div>
  );
};

const CompareBarChart = ({
  data,
  chartWidthPercentage,
  barWidth,
  yAxisWidth,
  tooltipTrigger,
  showLegend = true,
  slantXAxisLabels = false,
  barColor,
}) => {
  if (!data || data.length === 0) {
    return <div>No data available</div>;
  }

  const CustomXAxisTick = (props) => {
    const { x, y, payload } = props;
    const truncatedLabel = truncateLabel(payload.value, 15);

    return (
      <g transform={`translate(${x},${y})`}>
        <foreignObject
          x={-30}
          y={0}
          dy={slantXAxisLabels ? 16 : 24}
          width={80}
          height={60}
          marginTop={30}
          textAnchor="center"
        >
          <div
            xmlns="http://www.w3.org/1999/xhtml"
            style={{
              fontSize: "10px",
              color: "#71717A",
              transform: slantXAxisLabels ? "rotate(-35deg)" : "rotate(0deg)",
            }}
            dangerouslySetInnerHTML={{ __html: truncatedLabel }}
          />
        </foreignObject>
      </g>
    );
  };

  const CustomYAxisTick = (props) => {
    const { x, y, payload } = props;
    const truncatedLabel = truncateLabel(payload.value, 10);

    return (
      <g transform={`translate(${x},${y})`}>
        <text x={0} y={0} dy={4} fontSize={11} textAnchor="end" fill="#666">
          {truncatedLabel}
        </text>
      </g>
    );
  };

  const truncateLabel = (label, maxLength) => {
    if (label.length > maxLength) {
      let truncatedLabel = "";
      for (let i = 0; i < label.length; i += maxLength) {
        truncatedLabel += label.substring(i, i + maxLength) + "<br />";
      }
      return truncatedLabel;
    }
    return label;
  };

  const keyValues = Object.keys(data[0]).filter((key) => key !== "name");

  return (
    <ResponsiveContainer width={chartWidthPercentage} height={270}>
      <BarChart
        data={data}
        margin={{
          top: 10,
          right: 10,
          bottom: 0,
          left: 20,
        }}
      >
        <CartesianGrid stroke="#f5f5f5" />
        <XAxis
          dataKey="name"
          fontSize={10}
          tick={<CustomXAxisTick />}
          interval={0}
          marginTop={30}
          height={60}
        />
        <YAxis
          width={yAxisWidth ? yAxisWidth : 0}
          fontSize={11}
          tick={<CustomYAxisTick />}
          interval={0}
        />
        <Tooltip
          content={<CustomTooltip />}
          trigger={tooltipTrigger ? tooltipTrigger : "hover"}
        />
        {showLegend && <Legend content={<CustomLegend />} />}
        {keyValues.map((keyValue, index) => (
          <Bar
            key={index}
            dataKey={keyValue}
            fill={barColor || (keyValue === "Never" ? "#18181B" : "#747ED4")}
            name={keyValue}
            barSize={barWidth || 14}
            radius={[100, 100, 100, 100]}
          />
        ))}
      </BarChart>
    </ResponsiveContainer>
  );
};

export default CompareBarChart;
