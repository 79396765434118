import React from "react";

const MentalHealthIcon = ({ selected }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill={selected ? "#3F4E99" : "#71717A"}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M10.9998 2C12.9424 2.00013 14.8186 2.70688 16.2784 3.98843C17.7383 5.26999 18.6821 7.03882 18.9338 8.965L21.1838 12.504C21.3318 12.737 21.3018 13.084 20.9588 13.232L18.9998 14.07V17C18.9998 17.5304 18.7891 18.0391 18.414 18.4142C18.039 18.7893 17.5303 19 16.9998 19H15.0008L14.9998 22H5.99983V18.306C5.99983 17.126 5.56383 16.009 4.75483 15.001C3.81253 13.8245 3.22181 12.4056 3.05072 10.908C2.87964 9.41036 3.13515 7.89486 3.78782 6.53611C4.44049 5.17737 5.46377 4.03066 6.73974 3.22811C8.01571 2.42557 9.49246 1.99985 10.9998 2ZM10.4698 7.763C10.1398 7.44416 9.69782 7.26768 9.23898 7.27157C8.78013 7.27546 8.34117 7.45942 8.01664 7.78382C7.69211 8.10822 7.50798 8.5471 7.5039 9.00595C7.49982 9.46479 7.67612 9.90688 7.99483 10.237L10.9998 13.243L14.0048 10.237C14.1719 10.0755 14.3052 9.8824 14.3969 9.66888C14.4886 9.45535 14.5368 9.22571 14.5388 8.99334C14.5407 8.76098 14.4964 8.53055 14.4084 8.3155C14.3203 8.10045 14.1904 7.90509 14.026 7.74081C13.8617 7.57653 13.6663 7.44662 13.4512 7.35868C13.2361 7.27073 13.0056 7.2265 12.7733 7.22856C12.5409 7.23063 12.3113 7.27895 12.0978 7.37071C11.8843 7.46247 11.6912 7.59582 11.5298 7.763L10.9998 8.293L10.4698 7.763Z" />
  </svg>
);

export default MentalHealthIcon;
