import newLogo from "../assets/home/Naturals_logo.svg";
import styles from "../styles/components/common/Sidebar.module.css";
import ChartIcon from "../assets/home/chart-icon.svg";
import compicon from "../assets/demographics/compare-icon.svg";
const NotFound = () => {
  return (
    <div
      style={{
        height: "100vh",
        width: "100vw",
        backgroundColor: "#FFF",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        gap: "40px",
      }}
    >
      <img src={newLogo} alt="" height={52} />
      <h2 style={{ margin: "0px", color: "red" }}>404 Error</h2>
      <h1 style={{ margin: "0px", opacity: 0.7 }}>Page Not Found</h1>
      <p style={{ margin: "0px", opacity: 0.7 }}>
        Sorry, the page youre looking for could not be found.
      </p>

      <div
        className={styles.sidebarButtons}
        onClick={() => (window.location.pathname = "/")}
      >
        <img alt="" height={28} src={ChartIcon} />
        <p className={styles.text}>Go to dashboard home page</p>
      </div>

      <div
        className={styles.sidebarButtons}
        onClick={() => (window.location.pathname = "/brand-comparison")}
      >
        <img alt="" height={25} src={compicon} />
        <p className={styles.text}>Go to brand comparison page</p>
      </div>
    </div>
  );
};

export default NotFound;
