import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

const CustomTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    const data = payload[0].payload;
    return (
      <div className="custom-tooltip">
        {active && (
          <>
            <p
              style={{ fontSize: "15px", fontWeight: 400, opacity: 0.7 }}
            >{`Name: ${data.name}`}</p>
          </>
        )}
      </div>
    );
  }

  return null;
};

const customXAxisTick = (props) => {
  const { x, y, payload } = props;
  const truncatedLabel = truncateLabel(payload.value, 12);
  return (
    <g transform={`translate(${x},${y})`}>
      <text x={0} y={0} dy={12} fontSize={11} textAnchor="middle" fill="#666">
        {truncatedLabel}
      </text>
    </g>
  );
};

const CustomYAxisTick = (props) => {
  const { x, y, payload } = props;
  const truncatedLabel = truncateLabel(payload.value, 28);

  return (
    <g transform={`translate(${x},${y})`}>
      <text x={0} y={0} dy={4} fontSize={11} textAnchor="end" fill="#666">
        {truncatedLabel}
      </text>
    </g>
  );
};

const truncateLabel = (label, maxLength) => {
  if (label.length > maxLength) {
    return label.substring(0, maxLength) + "...";
  }
  return label;
};
const SimpleBarChart = ({ data }) => {
  return (
    <ResponsiveContainer width="100%">
      <BarChart
        data={data}
        margin={{ top: 10, right: 10, left: 10, bottom: 20 }}
      >
        <CartesianGrid stroke="#f5f5f6" />
        <XAxis tick={customXAxisTick} interval={0} dataKey="name" />
        <YAxis width={22} tick={CustomYAxisTick} interval={0} />
        <Tooltip content={<CustomTooltip />} />
        <Bar dataKey="value" fill="#747ED4" radius={[8, 8, 0, 0]} />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default SimpleBarChart;
