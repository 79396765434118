import React from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import "./App.css";
import NotFound from "./components/404";
import FitnessPage from "./pages/fitness";
import GutHealth from "./pages/gutHealth";
import MenstrualCare from "./pages/menstrualCare";
import MentalHealthPage from "./pages/mentalHealth";
import Sleep from "./pages/sleep";

const App = () => {
  return (
    <Router>
      {/* <Navbar /> */}
      <Routes>
        {/* <Route path="/" element={<Home />} /> */}
        <Route path="/" element={<Sleep />} />
        <Route path="/gut-health" element={<GutHealth />} />
        <Route path="/mental-health" element={<MentalHealthPage/>}/>
        <Route path="/fitness" element={<FitnessPage/>}/>
        <Route path="/menstrual-care" element={<MenstrualCare/>}/>
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
};

export default App;
