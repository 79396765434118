import { useEffect, useState } from "react";
import chartIcon from "../../assets/demographics/chart-icon.svg";
import doughnutIcon from "../../assets/wellnessCommon/doughnutIcon.svg";
import SimpleBarChart from "../../components/BarChart";
import Section from "../../components/BrandComparisonSection";
import CompareBarChart from "../../components/CompareBarChart";
import SemiChart from "../../components/SemiDouughnutChart";
import fetchSurveyData from "../../services";
import styles from "../../styles/pages/gutHealth/habits.module.css";
import noDataFound from "../../assets/home/ProductsNoDataFound.svg"

const GutHealthData = require("../../data/gutHealth.json");

const GutHealthAndSkin = ({selectedFilters}) => {
  const [acneAffectedPartData, setAcneAffectedPartData] = useState([]);
  const [skincareConcernsData, setSkincareConcernsData] = useState([]);
  const [skinConcernsData, setSkinConcernsData] = useState([]);
  const [skinCondition1Data, setSkinCondition1Data] = useState([]);
  const [gutImpactData, setGutImpactData] = useState([]);

  useEffect(() => {
    const processGutImpactData = async () => {
      try {
        const responseData = await fetchSurveyData("DZjVHdXx", selectedFilters);
      const gutImpactCounts = {};
      responseData.forEach((entry) => {
        const { gut_impact } = entry;
        if (gut_impact) {
          if (gutImpactCounts[gut_impact]) {
            gutImpactCounts[gut_impact]++;
          } else {
            gutImpactCounts[gut_impact] = 1;
          }
        }
      });

      const gutImpactArray = Object.entries(gutImpactCounts).map(
        ([name, value]) => ({
          name,
          value,
          fill:
            name === "Yes" ? "#3F3F46" : name === "No" ? "#747ED4" : "#FCF36B",
        })
      );

      const sortedGutImpactArray = gutImpactArray.sort(
        (a, b) => b.value - a.value
      );
      setGutImpactData(sortedGutImpactArray);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  processGutImpactData();
}, [selectedFilters]);

  const fetchFormData = async () => {
    try {
      const responseData = await fetchSurveyData("DZjVHdXx", selectedFilters);
      const processData = (attributeName, dataKey, limit) => {
        const counts = {};
        responseData.forEach((entry) => {
          const value = entry[dataKey];
          if (value && value !== "") {
            const values = value.split(/,\s*(?![^()]*\))/);
            values.forEach((v) => {
              const trimmedValue = v.trim();
              if (trimmedValue !== "") {
                if (counts[trimmedValue]) {
                  counts[trimmedValue]++;
                } else {
                  counts[trimmedValue] = 1;
                }
              }
            });
          }
        });

        const dataArray = Object.entries(counts)
          .map(([name, value]) => ({ name: name || "Unknown", value }))
          .filter((entry) => entry.name !== "");

        const sortedArray = dataArray
          .sort((a, b) => b.value - a.value)
          .slice(0, limit);

        switch (attributeName) {
          case "acneAffectedPart":
            setAcneAffectedPartData(sortedArray);
            break;
          case "skincareConcerns":
            setSkincareConcernsData(sortedArray);
            break;
          case "skinConcerns":
            setSkinConcernsData(sortedArray);
            break;
          case "skinCondition1":
            setSkinCondition1Data(sortedArray);
            break;
          default:
            break;
        }
      };

      processData("acneAffectedPart", "acne_affected_part", 4);
      processData("skincareConcerns", "skincare_concerns", 4);
      processData("skinConcerns", "skin_concerns", 4);
      processData("skinCondition1", "skin_condition1", 4);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  fetchFormData();

  return (
    <div style={{ width: "100%" }}>
      <div className={styles.brandsSelectContainer}>
        <Section
          width={38}
          heading={
            <div className={styles.heading}>
              <img src={chartIcon} alt="" />
              <p>Which parts of your face do you often experience acne?</p>
            </div>
          }
          content={
            acneAffectedPartData.length > 0 ? (
              <SimpleBarChart data={acneAffectedPartData} />
            ) : (
              <div><img src={noDataFound} alt=""  style={{marginLeft: "60px"}} /></div>
            )
          }
        />

        <Section
          width={38}
          heading={
            <div className={styles.heading}>
              <img src={chartIcon} alt="" />
              <p>Body concerns</p>
            </div>
          }
          content={
            skincareConcernsData.length > 0 ? (
              <SimpleBarChart data={skincareConcernsData} />
            ) : (
              <div><img src={noDataFound} alt=""  style={{marginLeft: "60px"}} /></div>
            )
          }
        />
        <Section
          width={24}
          heading={
            <div className={styles.heading}>
              <img src={doughnutIcon} alt="" />
              <p>Do you feel your gut health is impacting your skin health?</p>
            </div>
          }
          content={
            gutImpactData.length > 0 ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  paddingBottom: "10px",
                  marginTop: "20px",
                }}
              >
                <SemiChart data={gutImpactData} label="Women 99%" />
                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    gap: "30px",
                  }}
                >
                  {gutImpactData.map((item, index) => (
                    <div
                      key={index}
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "10px",
                        justifyContent: "center",
                        alignItems: "center",
                        marginTop: "30px",
                      }}
                    >
                      <div
                        style={{
                          height: "16px",
                          width: "16px",
                          borderRadius: "50%",
                          backgroundColor: `${item.fill}`,
                        }}
                      ></div>
                      <div>{item.title || item.name}</div>
                    </div>
                  ))}
                </div>
              </div>
            ) : (
              <div><img src={noDataFound} alt=""  style={{marginLeft: "-20px"}} /></div>
            )
          }
        />
      </div>
      <div className={styles.brandsSelectContainer}>
        <Section
          width={50}
          heading={
            <div className={styles.heading}>
              <img src={chartIcon} alt="" />
              <p>Skin Concerns</p>
            </div>
          }
          content={            
            skinConcernsData.length > 0 ? (
              <CompareBarChart data={skinConcernsData} yAxisWidth={20}
              showLegend={false}
              slantXAxisLabels={true}/>
            ) : (
              <div><img src={noDataFound} alt=""  style={{marginLeft: "160px"}} /></div>
            )
          }
        />

        <Section
          width={50}
          heading={
            <div className={styles.heading}>
              <img src={chartIcon} alt="" />
              <p>Skin Conditions</p>
            </div>
          }
          content={
            skinCondition1Data.length > 0 ? (
              <CompareBarChart data={skinCondition1Data} yAxisWidth={20}
              showLegend={false}
              slantXAxisLabels={true}/>
            ) : (
              <div><img src={noDataFound} alt=""  style={{marginLeft: "160px"}} /></div>
            )
          }
        />
      </div>{" "}
    </div>
  );
};

export default GutHealthAndSkin;
