import React from "react";

const GutHealthIcon = ({ selected }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill={selected ? "#3F4E99" : "#71717A"}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M3.99984 18C4.66984 19.85 6.06984 22 11.9998 22C14.3598 22 17.0698 21.93 18.9998 20C19.9998 19 21.9998 17 21.9998 11C21.9998 5 19.9998 4 17.9998 4C16.6198 4 14.9998 4 13.9998 6V6.03C13.8198 6.35638 13.544 6.61962 13.2096 6.78416C12.8751 6.94869 12.4982 7.00658 12.1298 6.95C10.9998 6.81 10.9998 6.37 10.9998 6V2H8.99984V6C8.9888 6.39697 9.05886 6.79201 9.20572 7.16098C9.35258 7.52994 9.57315 7.86507 9.85396 8.14588C10.1348 8.42668 10.4699 8.64726 10.8389 8.79412C11.2078 8.94098 11.6029 9.01103 11.9998 9C12.9998 9 12.9998 10.78 12.9998 12C12.9998 13.89 12.4998 15.26 10.9998 16C8.68984 17.15 6.38984 17 5.60984 15.47C5.52952 15.229 5.38929 15.0123 5.20231 14.8403C5.01533 14.6683 4.78773 14.5466 4.54084 14.4866C4.29396 14.4266 4.03589 14.4303 3.79084 14.4974C3.54579 14.5645 3.32178 14.6927 3.13984 14.87C2.71641 15.2648 2.39239 15.7542 2.19426 16.2982C1.99613 16.8422 1.92951 17.4253 1.99984 18V22H3.99984V18Z" />
  </svg>
);

export default GutHealthIcon;
