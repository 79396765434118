import { useEffect, useState } from "react";
import chartIcon from "../../assets/home/chart-icon.svg";
import genderIcon from "../../assets/home/gender-icon.svg";
import SimpleBarChart from "../../components/BarChart";
import Section from "../../components/BrandComparisonSection";
import CompareBarChart from "../../components/CompareBarChart";
import SemiChart from "../../components/SemiDouughnutChart";
import fetchSurveyData from "../../services/index";
import styles from "../../styles/pages/menstrualCare/habits.module.css";
import noDataFound from "../../assets/home/ProductsNoDataFound.svg"

const MentalHealthData = require("../../data/mentalHealth.json");

const MentalHealth = (selectedFilters) => {
  const [mentalFeelingData, setMentalFeelingData] = useState([]);
  const [relieveStressActivityData, setRelieveStressActivityData] = useState([]);
  const [stressReasonData, setStressReasonData] = useState([]);
  const [socialMediaImpactData, setSocialMediaImpactData] = useState([]);
  const [dailyStressLevelData, setDailyStressLevelData] = useState([]);

  useEffect(() => {
    const processDailyStressLevelData = async () => {
      try {
        const responseData = await fetchSurveyData("v4nncJLq", selectedFilters);
        const counts = {};
        responseData.forEach((entry) => {
          const { daily_stress_level } = entry;
          if (daily_stress_level) {
            if (counts[daily_stress_level]) {
              counts[daily_stress_level]++;
            } else {
              counts[daily_stress_level] = 1;
            }
          }
        });

        const dailyStressLevelArray = Object.entries(counts).map(
          ([name, value]) => ({
            name,
            value,
            fill:
              name === "Somewhat stressed"
                ? "#747ED4"
                : name === "Very stressed"
                ? "#3F3F46"
                : "#FCF36B",
          })
        );

        const sortedDailyStressLevelArray = dailyStressLevelArray.sort(
          (a, b) => b.value - a.value
        );
        setDailyStressLevelData(sortedDailyStressLevelArray);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    processDailyStressLevelData();
  }, [selectedFilters]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const responseData = await fetchSurveyData("v4nncJLq", selectedFilters);
        const processData = (attributeName, dataKey, limit) => {
          const counts = {};
          responseData.forEach((entry) => {
            const value = entry[dataKey];
            if (value && value !== "") {
              const values = value.split(/,\s*(?![^()]*\))/);
              values.forEach((v) => {
                const trimmedValue = v.trim();
                if (trimmedValue !== "") {
                  if (counts[trimmedValue]) {
                    counts[trimmedValue]++;
                  } else {
                    counts[trimmedValue] = 1;
                  }
                }
              });
            }
          });

          const dataArray = Object.entries(counts)
            .map(([name, value]) => ({ name: name || "Unknown", value }))
            .filter((entry) => entry.name !== "");

          const sortedArray = dataArray
            .sort((a, b) => b.value - a.value)
            .slice(0, limit);

          switch (attributeName) {
            case "mentalFeeling":
              setMentalFeelingData(sortedArray);
              break;
            case "relieveStressActivity":
              setRelieveStressActivityData(sortedArray);
              break;
            case "stressReason":
              setStressReasonData(sortedArray);
              break;
            case "socialMediaImpact":
              setSocialMediaImpactData(sortedArray);
              break;
            default:
              break;
          }
        };

        processData("mentalFeeling", "mental_feeling", 5);
        processData("relieveStressActivity", "relieve_stress_activity", 5);
        processData("stressReason", "stress_reason", 7);
        processData("socialMediaImpact", "social_media_impact", 7);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [selectedFilters]);

  return (
    <div style={{ width: "100%" }}>
      <div className={styles.brandsSelectContainer}>
        <Section
          width={33}
          heading={
            <div className={styles.heading}>
              <img src={chartIcon} alt="" />
              <p>How have you been feeling?</p>
            </div>
          }
          content={
            mentalFeelingData.length > 0 ? (
              <SimpleBarChart data={mentalFeelingData} />
            ) : (
              <div><img src={noDataFound} alt=""  style={{marginLeft: "20px"}} /></div>
            )
          }
        />
        <Section
          width={33}
          heading={
            <div className={styles.heading}>
              <img src={genderIcon} alt="" />
              <p>On a daily basis, how stressed do you feel?</p>
            </div>
          }
          content={
            dailyStressLevelData.length > 0 ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  paddingBottom: "10px",
                  marginTop: "10px",
                }}
              >
                <SemiChart data={dailyStressLevelData} label="Women 99%" />
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    // flexWrap: "wrap",
                    gap: "0px",
                  }}
                >
                  {dailyStressLevelData.map((item, index) => (
                    <div
                      key={index}
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "10px",
                        justifyContent: "center",
                        alignItems: "center",
                        marginTop: "10px",
                      }}
                    >
                      <div
                        style={{
                          height: "16px",
                          width: "16px",
                          borderRadius: "50%",
                          backgroundColor: `${item.fill}`,
                        }}
                      ></div>
                      <div>{item.title || item.name}</div>
                    </div>
                  ))}
                </div>
              </div>
            ) : (
              <div><img src={noDataFound} alt=""  style={{marginLeft: "20px"}} /></div>
            )
          }
        />

        <Section
          width={33}
          heading={
            <div className={styles.heading}>
              <img src={chartIcon} alt="" />
              <p>
                Are you doing any of the following to improve your mental
                health?
              </p>
            </div>
          }
          content={
            relieveStressActivityData.length > 0 ? (
              <SimpleBarChart data={relieveStressActivityData} />
            ) : (
              <div><img src={noDataFound} alt=""  style={{marginLeft: "20px"}} /></div>
            )
          }
        />
      </div>

      <div className={styles.brandsSelectContainer}>
        <Section
          width={67}
          heading={
            <div className={styles.heading}>
              <img src={chartIcon} alt="" />
              <p>What makes you feel stressed or anxious?</p>
            </div>
          }
          content={
            stressReasonData.length > 0 ? (
              <SimpleBarChart data={stressReasonData} />
            ) : (
              <div><img src={noDataFound} alt=""  style={{marginLeft: "260px"}} /></div>
            )
          }
        />

        <Section
          width={33}
          heading={
            <div className={styles.heading}>
              <img src={chartIcon} alt="" />
              <p>Do you feel social media impacts your mental health?</p>
            </div>
          }
          content={            
            socialMediaImpactData.length > 0 ? (
              <CompareBarChart data={socialMediaImpactData} yAxisWidth={20}
              showLegend={false} />
            ) : (
              <div><img src={noDataFound} alt=""  style={{marginLeft: "20px"}} /></div>
            )
          }
        />
      </div>
    </div>
  );
};

export default MentalHealth;
