// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MultiFilter_filterCapsule__DllG0 {
  background-color: #e4e4e7;
  border-radius: 12px;
  padding: 5px 10px;
  margin: 0px;
  white-space: "nowrap";
  cursor: pointer;
  font-size: 16px;
  opacity: 0.8;
  line-height: 24px;
}

.MultiFilter_filterTitle__gv8zU {
  font-weight: 700;
  font-size: 18px;
  line-height: 27px;
  margin: 4px 0px 8px 0px;
}

.MultiFilter_container__2MlJg {
  width: 500px;
}

.MultiFilter_activeFilter__MmTjF {
  background-color: #3F4E99;
  color: #fff;
  border-radius: 12px;
  padding: 5px 10px;
  margin: 0px;
  white-space: "nowrap";
  cursor: pointer;
  font-size: 16px;
  opacity: 1;
  line-height: 24px;
}

@media (max-width: 1200px) {
  .MultiFilter_container__2MlJg {
    width: auto;
  }
}
`, "",{"version":3,"sources":["webpack://./src/styles/components/MultiFilter.module.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,mBAAmB;EACnB,iBAAiB;EACjB,WAAW;EACX,qBAAqB;EACrB,eAAe;EACf,eAAe;EACf,YAAY;EACZ,iBAAiB;AACnB;;AAEA;EACE,gBAAgB;EAChB,eAAe;EACf,iBAAiB;EACjB,uBAAuB;AACzB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,yBAAyB;EACzB,WAAW;EACX,mBAAmB;EACnB,iBAAiB;EACjB,WAAW;EACX,qBAAqB;EACrB,eAAe;EACf,eAAe;EACf,UAAU;EACV,iBAAiB;AACnB;;AAEA;EACE;IACE,WAAW;EACb;AACF","sourcesContent":[".filterCapsule {\n  background-color: #e4e4e7;\n  border-radius: 12px;\n  padding: 5px 10px;\n  margin: 0px;\n  white-space: \"nowrap\";\n  cursor: pointer;\n  font-size: 16px;\n  opacity: 0.8;\n  line-height: 24px;\n}\n\n.filterTitle {\n  font-weight: 700;\n  font-size: 18px;\n  line-height: 27px;\n  margin: 4px 0px 8px 0px;\n}\n\n.container {\n  width: 500px;\n}\n\n.activeFilter {\n  background-color: #3F4E99;\n  color: #fff;\n  border-radius: 12px;\n  padding: 5px 10px;\n  margin: 0px;\n  white-space: \"nowrap\";\n  cursor: pointer;\n  font-size: 16px;\n  opacity: 1;\n  line-height: 24px;\n}\n\n@media (max-width: 1200px) {\n  .container {\n    width: auto;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"filterCapsule": `MultiFilter_filterCapsule__DllG0`,
	"filterTitle": `MultiFilter_filterTitle__gv8zU`,
	"container": `MultiFilter_container__2MlJg`,
	"activeFilter": `MultiFilter_activeFilter__MmTjF`
};
export default ___CSS_LOADER_EXPORT___;
