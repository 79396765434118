import { useEffect, useState } from "react";
import chartIcon from "../../assets/demographics/chart-icon.svg";
import doughnutIcon from "../../assets/wellnessCommon/doughnutIcon.svg";
import SimpleBarChart from "../../components/BarChart";
import Section from "../../components/BrandComparisonSection";
import CompareBarChart from "../../components/CompareBarChart";
import SemiChart from "../../components/SemiDouughnutChart";
import fetchSurveyData from "../../services/index";
import styles from "../../styles/pages/sleep/habits.module.css";
import noDataFound from "../../assets/home/ProductsNoDataFound.svg"

const GutHealthData = require("../../data/gutHealth.json");

const Consumption = ({selectedFilters}) => {
  const [fruitsIntakeData, setFruitsIntakeData] = useState([]);
  const [waterConsumptionData, setWaterConsumptionData] = useState([]);
  const [allergiesData, setAllergiesData] = useState([]);
  const [supplementsIntakeData, setSupplementsIntakeData] = useState([]);
  const [dietIncludesData, setDietIncludesData] = useState([]);
  const [antibioticsData, setAntibioticsData] = useState([]);

  useEffect(() => {
    const processAntibioticsIntakeeData = async () => {
      try {
        const responseData = await fetchSurveyData("DZjVHdXx", selectedFilters);
        const antibioticsIntakeCounts = {};
        responseData.forEach((entry) => {
          const { antibiotics_intake } = entry;
          if (antibiotics_intake) {
            if (antibioticsIntakeCounts[antibiotics_intake]) {
              antibioticsIntakeCounts[antibiotics_intake]++;
            } else {
              antibioticsIntakeCounts[antibiotics_intake] = 1;
            }
          }
        });
        const antibioticsIntakeArray = Object.entries(
          antibioticsIntakeCounts
        ).map(([name, value]) => ({
          name,
          value,
          fill:
            name === "Yes" ? "#FCF36B" : name === "No" ? "#747ED4" : "#3F3F46",
        }));

        const sortedAntibioticsIntakeArrayArray = antibioticsIntakeArray.sort(
          (a, b) => b.value - a.value
        );
        setAntibioticsData(sortedAntibioticsIntakeArrayArray);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    processAntibioticsIntakeeData();
  }, [selectedFilters]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const responseData = await fetchSurveyData("DZjVHdXx", selectedFilters);
        const processData = (attributeName, dataKey, limit) => {
          const counts = {};
          responseData.forEach((entry) => {
            const value = entry[dataKey];
            if (value && value !== "") {
              const values = value.split(/,\s*(?![^()]*\))/);
              values.forEach((v) => {
                const trimmedValue = v.trim();
                if (trimmedValue !== "") {
                  if (counts[trimmedValue]) {
                    counts[trimmedValue]++;
                  } else {
                    counts[trimmedValue] = 1;
                  }
                }
              });
            }
          });

          const dataArray = Object.entries(counts)
            .map(([name, value]) => ({ name: name || "Unknown", value }))
            .filter((entry) => entry.name !== "");

          const sortedArray = dataArray
            .sort((a, b) => b.value - a.value)
            .slice(0, limit);

          switch (attributeName) {
            case "fruitsIntake":
              setFruitsIntakeData(sortedArray);
              break;
            case "waterConsumption":
              setWaterConsumptionData(sortedArray);
              break;
            case "allergies":
              setAllergiesData(sortedArray);
              break;
            case "supplementsIntake":
              setSupplementsIntakeData(sortedArray);
              break;
            case "dietIncludes":
              setDietIncludesData(sortedArray);
              break;
            default:
              break;
          }
        };
        processData("fruitsIntake", "fruits_intake", 5);
        processData("waterConsumption", "water_consumption", 5);
        processData("allergies", "allergies", 5);
        processData("supplementsIntake", "supplements_intake", 6);
        processData("dietIncludes", "diet_includes", 13);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [selectedFilters]);

  return (
    <div style={{ width: "100%" }}>
      <div className={styles.brandsSelectContainer}>
        <Section
          width={45}
          heading={
            <div className={styles.heading}>
              <img src={chartIcon} alt="" />
              <p>Daily Fruit intake</p>
            </div>
          }
          content={
            fruitsIntakeData.length > 0 ? (
              <CompareBarChart data={fruitsIntakeData} yAxisWidth={20}
              showLegend={false} />
            ) : (
              <div><img src={noDataFound} alt=""  style={{marginLeft: "90px"}} /></div>
            )
          }
        />
        <Section
          width={30}
          heading={
            <div className={styles.heading}>
              <img src={chartIcon} alt="" />
              <p>Water Consumption</p>
            </div>
          }
          content={
            waterConsumptionData.length > 0 ? (
              <CompareBarChart data={waterConsumptionData} yAxisWidth={20}
              showLegend={false} />
            ) : (
              <div><img src={noDataFound} alt=""  style={{marginLeft: "20px"}} /></div>
            )
          }
        />
        <Section
          width={25}
          heading={
            <div className={styles.heading}>
              <img src={doughnutIcon} alt="" />
              <p>Do you take antibiotics regularly?</p>
            </div>
          }
          content={
            antibioticsData.length > 0 ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  paddingBottom: "10px",
                  marginTop: "20px",
                }}
              >
                <SemiChart data={antibioticsData} label="Women 99%" />
                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    gap: "30px",
                  }}
                >
                  {antibioticsData.map((item, index) => (
                    <div
                      key={index}
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "10px",
                        justifyContent: "center",
                        alignItems: "center",
                        marginTop: "30px",
                      }}
                    >
                      <div
                        style={{
                          height: "16px",
                          width: "16px",
                          borderRadius: "50%",
                          backgroundColor: `${item.fill}`,
                        }}
                      ></div>
                      <div>{item.title || item.name}</div>
                    </div>
                  ))}
                </div>
              </div>
            ) : (
              <div><img src={noDataFound} alt=""  style={{marginLeft: "-10px"}} /></div>
            )
          }
        />
      </div>
      <div className={styles.brandsSelectContainer}>
        <Section
          width={50}
          heading={
            <div className={styles.heading}>
              <img src={chartIcon} alt="" />
              <p>Allergies</p>
            </div>
          }
          content={
            allergiesData.length > 0 ? (
              <SimpleBarChart data={allergiesData} />
            ) : (
              <div><img src={noDataFound} alt=""  style={{marginLeft: "25%"}} /></div>
            )
          }
        />

        <Section
          width={50}
          heading={
            <div className={styles.heading}>
              <img src={chartIcon} alt="" />
              <p>Do you take any of the following supplements?</p>
            </div>
          }
          content={
            supplementsIntakeData.length > 0 ? (
              <CompareBarChart data={supplementsIntakeData}
              yAxisWidth={20}
              showLegend={false}
              slantXAxisLabels={true} />
            ) : (
              <div><img src={noDataFound} alt=""  style={{marginLeft: "25%"}} /></div>
            )
          }
        />
      </div>
      <div className={styles.brandsSelectContainer}>
        <Section
          width={100}
          heading={
            <div className={styles.heading}>
              <img src={chartIcon} alt="" />
              <p>Diet</p>
            </div>
          }
          content={
            dietIncludesData.length > 0 ? (
              <CompareBarChart data={dietIncludesData}
              yAxisWidth={20}
              showLegend={false}
              slantXAxisLabels={true} />
            ) : (
              <div><img src={noDataFound} alt=""  style={{marginLeft: "440px"}} /></div>
            )
          }
        />
      </div>{" "}
    </div>
  );
};

export default Consumption;
