import { useState, useEffect } from "react";
import chartIcon from "../../assets/home/chart-icon.svg";
import genderIcon from "../../assets/home/gender-icon.svg";
import SimpleBarChart from "../../components/BarChart";
import Section from "../../components/BrandComparisonSection";
import CompareBarChart from "../../components/CompareBarChart";
import SemiChart from "../../components/SemiDouughnutChart";
import styles from "../../styles/pages/menstrualCare/habits.module.css";
import fetchSurveyData from "../../services/index";
import noDataFound from "../../assets/home/ProductsNoDataFound.svg";

const FitnessData = require("../../data/fitnessData.json");

const Fitness = ({ selectedFilters }) => {
  const [exerciseFrequencyData, setExerciseFrequencyData] = useState([]);
  const [workoutPreferenceData, setWorkoutPreferenceData] = useState([]);
  const [workoutTimeData, setWorkoutTimeData] = useState([]);
  const [likedWorkoutsData, setLikedWorkoutsData] = useState([]);
  const [workoutSweatData, setWorkoutSweatData] = useState([]);
  const [exerciseLacksData, setExerciseLacksData] = useState([]);

  useEffect(() => {
    const processExerciseLacksData = async () => {
      try {
        const responseData = await fetchSurveyData("A3n7BzDW", selectedFilters);
        const exerciseLacksCounts = {};
        responseData.forEach((entry) => {
          const { exercise_lacks } = entry;
          if (exercise_lacks) {
            if (exerciseLacksCounts[exercise_lacks]) {
              exerciseLacksCounts[exercise_lacks]++;
            } else {
              exerciseLacksCounts[exercise_lacks] = 1;
            }
          }
        });
        const exerciseLacksArray = Object.entries(exerciseLacksCounts).map(
          ([name, value]) => ({
            name,
            value,
            fill:
              name === "Yes"
                ? "#FCF36B"
                : name === "No"
                ? "#747ED4"
                : "#3F3F46",
          })
        );

        const sortedExerciseLacksArray = exerciseLacksArray.sort(
          (a, b) => b.value - a.value
        );
        setExerciseLacksData(sortedExerciseLacksArray);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    processExerciseLacksData();
  }, [selectedFilters]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const responseData = await fetchSurveyData("A3n7BzDW", selectedFilters);
        const processData = (attributeName, dataKey, limit) => {
          const counts = {};
          responseData.forEach((entry) => {
            const value = entry[dataKey];
            if (value && value !== "") {
              const values = value.split(/,\s*(?![^()]*\))/);
              values.forEach((v) => {
                const trimmedValue = v.trim();
                if (trimmedValue !== "") {
                  if (counts[trimmedValue]) {
                    counts[trimmedValue]++;
                  } else {
                    counts[trimmedValue] = 1;
                  }
                }
              });
            }
          });

          const dataArray = Object.entries(counts)
            .map(([name, value]) => ({ name: name || "Unknown", value }))
            .filter((entry) => entry.name !== "");

          const sortedArray = dataArray
            .sort((a, b) => b.value - a.value)
            .slice(0, limit);

          switch (attributeName) {
            case "exerciseFrequency":
              setExerciseFrequencyData(sortedArray);
              break;
            case "workoutPreference":
              setWorkoutPreferenceData(sortedArray);
              break;
            case "workoutTime":
              setWorkoutTimeData(sortedArray);
              break;
            case "likedWorkouts":
              setLikedWorkoutsData(sortedArray);
              break;
            case "workoutSweat":
              setWorkoutSweatData(sortedArray);
              break;
            default:
              break;
          }
        };

        processData("exerciseFrequency", "exercise_frequency", 5);
        processData("workoutPreference", "workout_preference", 4);
        processData("workoutTime", "workout_time", 4);
        processData("likedWorkouts", "liked_workouts", 4);
        processData("workoutSweat", "workout_sweat", 3);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [selectedFilters]);

  return (
    <div style={{ width: "100%" }}>
      <div className={styles.brandsSelectContainer}>
        <Section
          width={33}
          heading={
            <div className={styles.heading}>
              <img src={chartIcon} alt="" />
              <p>How often do you exercise?</p>
            </div>
          }
          content={
            exerciseFrequencyData.length > 0 ? (
              <SimpleBarChart data={exerciseFrequencyData} />
            ) : (
              <div>
                <img
                  src={noDataFound}
                  alt="" style={{marginLeft: "30px"}}
                />
              </div>
            )
          }
        />
        <Section
          width={33}
          heading={
            <div className={styles.heading}>
              <img src={genderIcon} alt="" />
              <p>Do you lack motivation to exercise?</p>
            </div>
          }
          content={
            exerciseLacksData.length > 0 ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  paddingBottom: "10px",
                  marginTop: "20px",
                }}
              >
                <SemiChart data={exerciseLacksData} label="Women 99%" />
                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    gap: "30px",
                  }}
                >
                  {exerciseLacksData.map((item, index) => (
                    <div
                      key={index}
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "10px",
                        justifyContent: "center",
                        alignItems: "center",
                        marginTop: "30px",
                      }}
                    >
                      <div
                        style={{
                          height: "16px",
                          width: "16px",
                          borderRadius: "50%",
                          backgroundColor: `${item.fill}`,
                        }}
                      ></div>
                      <div>{item.title || item.name}</div>
                    </div>
                  ))}
                </div>
              </div>
            ) : (
              <div>
                <img
                  src={noDataFound}
                  alt="" style={{marginLeft: "30px"}}
                />
              </div>
            )
          }
        />

        <Section
          width={33}
          heading={
            <div className={styles.heading}>
              <img src={chartIcon} alt="" />
              <p>Workout Preferences</p>
            </div>
          }
          content={
            workoutPreferenceData.length > 0 ? (
              <SimpleBarChart data={workoutPreferenceData} />
            ) : (
              <div>
                <img src={noDataFound} alt=""  style={{marginLeft: "30px"}} />
              </div>
            )
          }
        />
      </div>

      <div className={styles.brandsSelectContainer}>
        <Section
          width={50}
          heading={
            <div className={styles.heading}>
              <img src={chartIcon} alt="" />
              <p>When do you like to workout?</p>
            </div>
          }
          content={
            workoutTimeData.length > 0 ? (
              <CompareBarChart data={workoutTimeData}  yAxisWidth={20}
              showLegend={false}/>
            ) : (
              <div>
                <img src={noDataFound} alt=""  style={{marginLeft: "30px"}} />
              </div>
            )
          }
        />

        <Section
          width={50}
          heading={
            <div className={styles.heading}>
              <img src={chartIcon} alt="" />
              <p>Workout Activities</p>
            </div>
          }
          content={
            likedWorkoutsData.length > 0 ? (
              <CompareBarChart data={likedWorkoutsData}  yAxisWidth={20}
              showLegend={false}/>
            ) : (
              <div>
                <img src={noDataFound} alt="" style={{marginLeft: "30px"}} />
              </div>
            )
          }
        />

        <Section
          width={33}
          heading={
            <div className={styles.heading}>
              <img src={chartIcon} alt="" />
              <p>How much do you sweat?</p>
            </div>
          }
          content={
            workoutSweatData.length > 0 ? (
              <SimpleBarChart data={workoutSweatData} />
            ) : (
              <div>
                <img
                  src={noDataFound}
                  alt=""
                  style={{marginLeft: "30px"}}
                />
              </div>
            )
          }
        />
      </div>
    </div>
  );
};

export default Fitness;
