import { useState } from "react";
import chartIcon from "../../assets/demographics/chart-icon.svg";
import SimpleBarChart from "../../components/BarChart";
import Section from "../../components/BrandComparisonSection";
import CompareBarChart from "../../components/CompareBarChart";
import fetchSurveyData from "../../services";
import styles from "../../styles/pages/sleep/habits.module.css";
import noDataFound from "../../assets/home/ProductsNoDataFound.svg"

const GutHealthData = require("../../data/gutHealth.json");

const Diet = ({selectedFilters}) => {
  const [vegetablesIntakeData, setVegetablesIntakeData] = useState([]);
  const [followsDietData, setFollowsDietData] = useState([]);
  const [mealsPerDayData, setMealsPerDayData] = useState([]);
  const [bowelMovementsData, setBowelMovementsData] = useState([]);
  const [gutExperienceData, setGutExperienceData] = useState([]);

  const fetchFormData = async () => {
    try {
      const responseData = await fetchSurveyData("DZjVHdXx", selectedFilters);
      const processData = (attributeName, dataKey, limit) => {
        const counts = {};
        responseData.forEach((entry) => {
          const value = entry[dataKey];
          if (value && value !== "") {
            const values = value.split(/,\s*(?![^()]*\))/);
            values.forEach((v) => {
              const trimmedValue = v.trim();
              if (trimmedValue !== "") {
                if (counts[trimmedValue]) {
                  counts[trimmedValue]++;
                } else {
                  counts[trimmedValue] = 1;
                }
              }
            });
          }
        });

        const dataArray = Object.entries(counts)
          .map(([name, value]) => ({ name: name || "Unknown", value }))
          .filter((entry) => entry.name !== "");

        const sortedArray = dataArray
          .sort((a, b) => b.value - a.value)
          .slice(0, limit);

        switch (attributeName) {
          case "vegetablesIntake":
            setVegetablesIntakeData(sortedArray);
            break;
          case "followsDiet":
            setFollowsDietData(sortedArray);
            break;
          case "mealsPerDay":
            setMealsPerDayData(sortedArray);
            break;
          case "bowelMovements":
            setBowelMovementsData(sortedArray);
            break;
          case "gutExperience":
            setGutExperienceData(sortedArray);
            break;
          default:
            break;
        }
      };

      processData("vegetablesIntake", "vegetables_intake", 5);
      processData("followsDiet", "follows_diet", 5);
      processData("mealsPerDay", "meals_per_day", 5);
      processData("bowelMovements", "bowel_movements", 5);
      processData("gutExperience", "gut_experience", 7);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  fetchFormData();

  return (
    <div style={{ width: "100%" }}>
      <div className={styles.brandsSelectContainer}>
        <Section
          width={25}
          heading={
            <div className={styles.heading}>
              <img src={chartIcon} alt="" />
              <p>
                How many servings of vegetables are featured in your daily diet?
              </p>
            </div>
          }
          content={
            vegetablesIntakeData.length > 0 ? (
              <CompareBarChart data={vegetablesIntakeData}
              yAxisWidth={20}
              showLegend={false}/>
            ) : (
              <div><img src={noDataFound} alt="" style={{marginLeft: "-10px"}}/></div>
            )
          }
        />
        <Section
          width={25}
          heading={
            <div className={styles.heading}>
              <img src={chartIcon} alt="" />
              <p>Do you follow any particular diets?</p>
            </div>
          }
          content={
            followsDietData.length > 0 ? (
              <CompareBarChart data={followsDietData}
              yAxisWidth={20}
              showLegend={false}/>
            ) : (
              <div><img src={noDataFound} alt=""style={{marginLeft: "-10px"}}/></div>
            )
          }
        />
        <Section
          width={50}
          heading={
            <div className={styles.heading}>
              <img src={chartIcon} alt="" />
              <p>Meals per day</p>
            </div>
          }
          content={
            mealsPerDayData.length > 0 ? (
              <SimpleBarChart data={mealsPerDayData} />
            ) : (
              <div><img src={noDataFound} alt=""  style={{marginLeft: "120px"}} /></div>
            )
          }
        />
      </div>
      <div className={styles.brandsSelectContainer}>
        <Section
          width={35}
          heading={
            <div className={styles.heading}>
              <img src={chartIcon} alt="" />
              <p>How frequent are your bowel movements?</p>
            </div>
          }
          content={
            bowelMovementsData.length > 0 ? (
              <CompareBarChart data={bowelMovementsData}
              yAxisWidth={20}
              showLegend={false}/>
            ) : (
              <div><img src={noDataFound} alt="" style={{marginLeft: "40px"}}/></div>
            )
          }
        />
        <Section
          width={65}
          heading={
            <div className={styles.heading}>
              <img src={chartIcon} alt="" />
              <p>Do you experience any of the following regularly?</p>
            </div>
          }
          content={
            gutExperienceData.length > 0 ? (
              <SimpleBarChart data={gutExperienceData} />
            ) : (
              <div><img src={noDataFound} alt=""  style={{marginLeft: "220px"}} /></div>
            )
          }
        />
      </div>
    </div>
  );
};

export default Diet;
