import React from "react";

const MenstrualCareIcon = ({ selected }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill={selected ? "#3F4E99" : "#71717A"}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M19 15.1315C19 18.925 15.866 22 12 22C8.134 22 5 18.925 5 15.1315C5 9.1215 12 2 12 2C12 2 19 9.121 19 15.1315Z" />
  </svg>
);

export default MenstrualCareIcon;
