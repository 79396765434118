import { useEffect, useState } from "react";
import chartIcon from "../../assets/demographics/chart-icon.svg";
import doughnutIcon from "../../assets/wellnessCommon/doughnutIcon.svg";
import SimpleBarChart from "../../components/BarChart";
import Section from "../../components/BrandComparisonSection";
import CompareBarChart from "../../components/CompareBarChart";
import CustomDonutChart from "../../components/doughnutChart";
import fetchSurveyData from "../../services";
import noDataFound from "../../assets/home/ProductsNoDataFound.svg"
import styles from "../../styles/pages/sleep/habits.module.css";

const SleepAndSkinData = require("../../data/sleepData.json");

const Habits = ({ selectedFilters }) => {
  const [index, setIndex] = useState(0);
  const [page, setPage] = useState(1);
  const handleChange = (e) => {
    setIndex(Number(e) - 1);
  };
  const [troubleSleepingData, setTroubleSleepingData] = useState([]);
  const [bedWakeTimeData, setBedWakeTimeData] = useState([]);
  const [averageSleepData, setAverageSleepData] = useState([]);
  const [awakeningFrequencyData, setAwakeningFrequencyData] = useState([]);

  const fetchFormData = async () => {
    try {
      const responseData = await fetchSurveyData("Tj8wToIm", selectedFilters);
      const processData = (attributeName, dataKey, limit) => {
        const counts = {};
        responseData.forEach((entry) => {
          const value = entry[dataKey];
          if (value && value !== "") {
            const values = value.split(/,\s*(?![^()]*\))/);
            values.forEach((v) => {
              const trimmedValue = v.trim();
              if (trimmedValue !== "") {
                if (counts[trimmedValue]) {
                  counts[trimmedValue]++;
                } else {
                  counts[trimmedValue] = 1;
                }
              }
            });
          }
        });

        const dataArray = Object.entries(counts)
          .map(([name, value]) => ({ name: name || "Unknown", value }))
          .filter((entry) => entry.name !== "");

        const sortedArray = dataArray
          .sort((a, b) => b.value - a.value)
          .slice(0, limit);

        switch (attributeName) {
          case "bedWakeTime":
            setBedWakeTimeData(sortedArray);
            break;
          case "averageSleep":
            setAverageSleepData(sortedArray);
            break;
          case "awakeningFrequency":
            setAwakeningFrequencyData(sortedArray);
            break;
          default:
            break;
        }
      };
      processData("bedWakeTime", "fix_bed_wake_time", 7);
      processData("averageSleep", "average_sleep", 7);
      processData("awakeningFrequency", "awakening_frequency", 7);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  fetchFormData();

  useEffect(() => {
    const processTroubleSleepingData = async () => {
      try {
        const responseData = await fetchSurveyData("Tj8wToIm", selectedFilters);
        const troubleSleepingCounts = {
          Yes: { value: 0, fill: "#747ED4" },
          No: { value: 0, fill: "#22C55E" },
          Sometimes: { value: 0, fill: "#FCF36B" },
        };

        responseData.forEach((entry) => {
          const { trouble_sleeping } = entry;
          if (trouble_sleeping && trouble_sleeping !== "") {
            if (troubleSleepingCounts[trouble_sleeping]) {
              troubleSleepingCounts[trouble_sleeping].value++;
            }
          }
        });

        const troubleSleepingArray = Object.entries(troubleSleepingCounts)
          .map(([name, { value, fill }]) => ({
            name: name || "Unknown",
            value,
            fill,
          }))
          .filter((entry) => entry.name !== "");

        const sortedTroubleSleepingArray = troubleSleepingArray.sort(
          (a, b) => b.value - a.value
        );

        setTroubleSleepingData(sortedTroubleSleepingArray);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    processTroubleSleepingData();
  }, [selectedFilters]);

  return (
    <div style={{ width: "100%" }}>
      <div className={styles.brandsSelectContainer}>
        <Section
          width={50}
          heading={
            <div className={styles.heading}>
              <img src={doughnutIcon} alt="" />
              <p>Do you have trouble falling asleep?</p>
            </div>
          }
          content={
            troubleSleepingData.length > 0 ? (
              <div
                style={{
                  display: "flex",
                  gap: "10px",
                }}
              >
                <div style={{ width: "65%", marginTop: "40px" }}>
                  <CustomDonutChart propData={troubleSleepingData} />
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    // flexWrap: "wrap",
                    gap: "10px",
                    width: "60%",
                    marginTop: "30px",
                  }}
                >
                  {troubleSleepingData.map((item, index) => (
                    <div
                      key={index}
                      style={{
                        height: "50px",
                        width: "80%",
                        border: "1px solid #F2F2F2",
                        display: "flex",
                        flexDirection: "row",
                        gap: "10px",
                        padding: "6px",
                        borderRadius: "8px",
                      }}
                    >
                      <div
                        style={{
                          height: "16px",
                          width: "16px",
                          borderRadius: "50%",
                          backgroundColor: `${item.fill}`,
                          gap: "10px",
                        }}
                      ></div>
                      <div>{item.title || item.name}</div>
                    </div>
                  ))}
                </div>
              </div>
            ) : (
              <div><img src={noDataFound} alt=""  style={{marginLeft: "120px"}} /></div>
            )
          }
        />

        <Section
          width={50}
          heading={
            <div className={styles.heading}>
              <img src={chartIcon} alt="" />
              <p>Do you have a set bedtime and wake up time?</p>
            </div>
          }
          content={
            bedWakeTimeData.length > 0 ? (
              <CompareBarChart data={bedWakeTimeData}  yAxisWidth={20}
              showLegend={false}/>
            ) : (
              <div><img src={noDataFound} alt=""  style={{marginLeft: "120px"}} /></div>
            )
          }
        />
      </div>
      <div className={styles.brandsSelectContainer}>
        <Section
          width={65}
          heading={
            <div className={styles.heading}>
              <img src={chartIcon} alt="" />
              <p>Hours of sleep</p>
            </div>
          }
          content={
            averageSleepData.length > 0 ? (
              <SimpleBarChart data={averageSleepData} />
            ) : (
              <div><img src={noDataFound} alt=""  style={{marginLeft: "250px"}} /></div>
            )
          }
        />

        <Section
          width={35}
          heading={
            <div className={styles.heading}>
              <img src={chartIcon} alt="" />
              <p>How often do you wake up once you sleep?</p>
            </div>
          }
          content={
            awakeningFrequencyData.length > 0 ? (
              <SimpleBarChart data={awakeningFrequencyData} />
            ) : (
              <div><img src={noDataFound} alt=""  style={{marginLeft: "50px"}} /></div>
            )
          }
        />
      </div>{" "}
    </div>
  );
};

export default Habits;
