const Section = ({ width, heading, content, height }) => {
  const sectionStyle = {
    width: `${width ? width : 35}%`,
    height: `${height ? height : 335}px`,
    backgroundColor: "#fff",
    padding: "0px 0px",
    borderRadius: "12px",
    display: "flex",
    flexDirection: "column",
    border: "1px solid #E4E4E7",
    transition: "transform 0.3s ease",
    ":hover": {
      transform: "scale(0.96)",
    },
  };
  return (
    <div style={sectionStyle}>
      {heading}
      {content}
    </div>
  );
};

export default Section;
