import { useState } from "react";
import chartIcon from "../../assets/home/chart-icon.svg";
import genderIcon from "../../assets/home/gender-icon.svg";
import SimpleBarChart from "../../components/BarChart";
import Section from "../../components/BrandComparisonSection";
import CompareBarChart from "../../components/CompareBarChart";
import SemiChart from "../../components/SemiDouughnutChart";
import fetchSurveyData from "../../services/index";
import styles from "../../styles/pages/sleep/habits.module.css";
import noDataFound from "../../assets/home/ProductsNoDataFound.svg"

const MentalHealthData = require("../../data/mentalHealth.json");

const MentalHealthAndSkin = ({selectedFilters}) => {
  const [beautyAdvertiseImpactData, setBeautyAdvertiseImpactData] = useState([]);
  const [skinConcernImpactData, setSkinConcernImpactData] = useState([]);
  const [skinConditionData, setSkinConditionData] = useState([]);
  const [skinConcernsData, setSkinConcernsData] = useState([]);
  const [skincareConcernsData, setSkincareConcernsData] = useState([]);

  const fetchFormData = async () => {
    try {
      const responseData = await fetchSurveyData("v4nncJLq", selectedFilters);
      const processData = (attributeName, dataKey, limit) => {
        const counts = {};
        responseData.forEach((entry) => {
          const value = entry[dataKey];
          if (value && value !== "") {
            const values = value.split(/,\s*(?![^()]*\))/);
            values.forEach((v) => {
              const trimmedValue = v.trim();
              if (trimmedValue !== "") {
                if (counts[trimmedValue]) {
                  counts[trimmedValue]++;
                } else {
                  counts[trimmedValue] = 1;
                }
              }
            });
          }
        });

        const dataArray = Object.entries(counts)
          .map(([name, value]) => ({ name: name || "Unknown", value }))
          .filter((entry) => entry.name !== "");

        const sortedArray = dataArray
          .sort((a, b) => b.value - a.value)
          .slice(0, limit);

        switch (attributeName) {
          case "skinCondition":
            setSkinConditionData(sortedArray);
            break;
          case "skinConcerns":
            setSkinConcernsData(sortedArray);
            break;
          case "skincareConcerns":
            setSkincareConcernsData(sortedArray);
            break;
          default:
            break;
        }
      };

      processData("skinCondition", "skin_condition", 6);
      processData("skinConcerns", "skin_concerns", 6);
      processData("skincareConcerns", "skincare_concerns", 7);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  fetchFormData();

  const fetchData = async () => {
    try {
      const responseData = await fetchSurveyData("v4nncJLq", selectedFilters);
      const processData = (attributeName, dataKey) => {
        const counts = { Yes: 0, No: 0, Sometimes: 0 };
        responseData.forEach((entry) => {
          const value = entry[dataKey];
          if (value && value !== "") {
            counts[value]++;
          }
        });

        const dataArray = Object.entries(counts).map(([name, value]) => ({
          name,
          value,
          fill:
            name === "Yes" ? "#FCF36B" : name === "No" ? "#747ED4" : "#3F3F46",
        }));

        switch (attributeName) {
          case "beautyAdvertiseImpact":
            setBeautyAdvertiseImpactData(dataArray);
            break;
          case "skinConcernImpact":
            setSkinConcernImpactData(dataArray);
            break;
          default:
            break;
        }
      };

      processData("beautyAdvertiseImpact", "beauty_advertise_impact");
      processData("skinConcernImpact", "skin_concern_impact");
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  fetchData();

  return (
    <div style={{ width: "100%" }}>
      <div className={styles.brandsSelectContainer}>
        <Section
          width={50}
          heading={
            <div className={styles.heading}>
              <img src={genderIcon} alt="" />
              <p>
                Are the beauty standards being advertised and marketed impacted
                your mental health?
              </p>
            </div>
          }
          content={
            beautyAdvertiseImpactData.length > 0 ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  paddingBottom: "10px",
                  marginTop: "20px",
                }}
              >
                <SemiChart data={beautyAdvertiseImpactData} label="Women 99%" />
                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    gap: "30px",
                  }}
                >
                  {beautyAdvertiseImpactData.map((item, index) => (
                    <div
                      key={index}
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "10px",
                        justifyContent: "center",
                        alignItems: "center",
                        marginTop: "30px",
                      }}
                    >
                      <div
                        style={{
                          height: "16px",
                          width: "16px",
                          borderRadius: "50%",
                          backgroundColor: `${item.fill}`,
                        }}
                      ></div>
                      <div>{item.title || item.name}</div>
                    </div>
                  ))}
                </div>
              </div>
            ) : (
              <div><img src={noDataFound} alt=""  style={{marginLeft: "20px"}} /></div>
            )
          }
        />

        <Section
          width={50}
          heading={
            <div className={styles.heading}>
              <img src={chartIcon} alt="" />
              <p>Skin concerns</p>
            </div>
          }
          content={ 
            skinConcernsData.length > 0 ? (
              <CompareBarChart data={skinConcernsData}  yAxisWidth={20}
              showLegend={false}
              slantXAxisLabels={true} />
            ) : (
              <div><img src={noDataFound} alt=""  style={{marginLeft: "20px"}} /></div>
            )
          }
        />

        <Section
          width={50}
          heading={
            <div className={styles.heading}>
              <img src={genderIcon} alt="" />
              <p>
                Do you feel your skin conditions or concerns are impacting your
                mental health?
              </p>
            </div>
          }
          content={
            skinConcernImpactData.length > 0 ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  paddingBottom: "10px",
                  marginTop: "20px",
                }}
              >
                <SemiChart data={skinConcernImpactData} label="Women 99%" />
                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    gap: "30px",
                  }}
                >
                  {skinConcernImpactData.map((item, index) => (
                    <div
                      key={index}
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "10px",
                        justifyContent: "center",
                        alignItems: "center",
                        marginTop: "30px",
                      }}
                    >
                      <div
                        style={{
                          height: "16px",
                          width: "16px",
                          borderRadius: "50%",
                          backgroundColor: `${item.fill}`,
                        }}
                      ></div>
                      <div>{item.title || item.name}</div>
                    </div>
                  ))}
                </div>
              </div>
            ) : (
              <div><img src={noDataFound} alt=""  style={{marginLeft: "20px"}} /></div>
            )
          }
        />
      </div>
      <div className={styles.brandsSelectContainer}>
        <Section
          width={50}
          heading={
            <div className={styles.heading}>
              <img src={chartIcon} alt="" />
              <p>Skin conditions</p>
            </div>
          }
          content={
            skinConditionData.length > 0 ? (
              <CompareBarChart data={skinConditionData}  yAxisWidth={20}
              showLegend={false}/>
            ) : (
              <div><img src={noDataFound} alt=""  style={{marginLeft: "80px"}} /></div>
            )
          }
        />

        <Section
          width={67}
          heading={
            <div className={styles.heading}>
              <img src={chartIcon} alt="" />
              <p>Body Concerns</p>
            </div>
          }
          content={
            skincareConcernsData.length > 0 ? (
              <SimpleBarChart data={skincareConcernsData} />
            ) : (
              <div><img src={noDataFound} alt=""  style={{marginLeft: "200px"}} /></div>
            )
          }
        />
      </div>{" "}
    </div>
  );
};

export default MentalHealthAndSkin;
