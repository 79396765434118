import React, { useState, useEffect } from "react";
import styles from "../../styles/pages/sleep/index.module.css";
import Sidebar from "../../components/Sidebar";
import Habits from "./habits";
import Activities from "./activities";
import SkinAndSleep from "./sleepAndSkin";
import SleepingHabitsIcon from "../../assets/wellnessCommon/sleepingHabitsIcon.svg";
import ActivitiesIcon from "../../assets/wellnessCommon/activitiesIcon.svg";
import SleepAndSkinIcon from "../../assets/wellnessCommon/SleepAndSkinIcon.svg";
import ForwardArrow from "../../assets/wellnessCommon/arrow_forwardLogo.svg";
import miniFilterIcon from "../../assets/home/mini-filter-icon.svg";
import MultiFilter from "../../components/MultiFilter";
import { Button, Drawer, Space } from "antd";
import filterIcon from "../../assets/home/filter-icon.svg";

const Sleep = () => {
  const [activeTab, setActiveTab] = useState("habits");
    const [index, setIndex] = useState(0);
  const [page, setPage] = useState(1);
  const handleChange = (e) => {
    setIndex(Number(e) - 1);
  };

  const [selectedFilters, setSelectedFilters] = useState({
    ageFilter: [],
    genderFilter: [],
    skinTypeFilter: [],
    cityFilter: [],
  });
  
  const handleUpdateFilter = (filters) => {
    setSelectedFilters(filters);
  };

  const [open, setOpen] = useState(false);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const renderTabContent = () => {
    switch (activeTab) {
      case "habits":
        return <Habits selectedFilters={selectedFilters} />;
      case "activities":
        return <Activities selectedFilters={selectedFilters} />;
      case "skin":
        return <SkinAndSleep selectedFilters={selectedFilters} />;
      default:
        return null;
    }
  };

  return (
    <div className={styles.container}>
      <Sidebar handleChange={handleChange} />
      <div className={styles.bodyContainer}>
        <div className={styles.subBodyContainer}>
          <div className={styles.topBar}>
            <h2>Sleep</h2>
            <img
              src={ForwardArrow}
              alt=""
              style={{ paddingBottom: "20px", marginLeft: "10px" }}
            />
            <div className={styles.tabContainer}>
              <div
                className={`${styles.subTabContainer} ${
                  activeTab === "habits" ? styles.buttonActive : ""
                }`}
                onClick={() => handleTabChange("habits")}
              >
                <img src={SleepingHabitsIcon} alt="Sleeping Habits" />
                Sleeping habits
              </div>
              <div
                className={`${styles.subTabContainer} ${
                  activeTab === "activities" ? styles.buttonActive : ""
                }`}
                onClick={() => handleTabChange("activities")}
              >
                <img src={ActivitiesIcon} alt="Activities" />
                Activities
              </div>
              <div
                className={`${styles.subTabContainer} ${
                  activeTab === "skin" ? styles.buttonActive : ""
                }`}
                onClick={() => handleTabChange("skin")}
              >
                <img src={SleepAndSkinIcon} alt="Sleep & Skin" />
                Sleep & skin
              </div>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
              }}
              onClick={showDrawer}
            >
              <img src={filterIcon} height={35} alt="" />
            </div>
            <Drawer
              placement="right"
              width="auto"
              onClose={onClose}
              open={open}
              extra={
                <Space>
                    <div style={{ display: "flex", gap: "8px" }}>
                      <img src={miniFilterIcon} alt="" />
                      <p className={styles.filterTitle}>Filter</p>
                    </div>
                </Space>
              }
            >
              <MultiFilter handleUpdateFilter={handleUpdateFilter} />
            </Drawer>
          </div>
          <div style={{ display: "flex", gap: "10px", marginBottom: "20px" }}>
            {renderTabContent()}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sleep;
