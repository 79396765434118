import React from "react";
import { PieChart, Pie, Legend, Label, Text, Tooltip } from "recharts";

const CustomTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    const data = payload[0].payload; 
    return (
      <div
        className="custom-tooltip"
        style={{
          backgroundColor: "#d3d3d3",
          padding: "4px 12px",
          borderRadius: "12px",
        }}
      >
        {active && (
          <>
            <p>{data.title}</p>
            <p>{data.value} </p>
          </>
        )}
      </div>
    );
  }

  return null;
};

const CustomLabel = ({ label, fill }) => (
  <text x={100} y={100} dy={8} textAnchor="middle" fill={fill} fontSize={12}>
    {label}
  </text>
);

const SemiChart = ({ data, label }) => {
  const total = data.reduce((sum, item) => sum + item.value, 0);
  const maxItem = data.reduce(
    (max, item) => (item.value > max.value ? item : max),
    data[0]
  );
  // const percentage = ((maxItem.value / total) * 100).toFixed(2);
  return (
    <PieChart width={200} height={170}>
      <Pie
        data={data}
        cx={100}
        cy={100}
        innerRadius={60}
        outerRadius={95}
        startAngle={210}
        endAngle={-30}
        paddingAngle={0}
        dataKey="value"
        stroke="color: '#808080'"
        cornerRadius={[20, 20, 0, 0]}
      >
        <Label
          content={
            <CustomLabel
              // label={`${maxItem.title} ${percentage}%`}
              fill={maxItem.fill}
            />
          }
        />
      </Pie>
      <Tooltip content={<CustomTooltip />} />
    </PieChart>
  );
};

export default SemiChart;
