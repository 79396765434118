import { Button, Drawer, Space } from "antd";
import React, { useState } from "react";
import filterIcon from "../../assets/home/filter-icon.svg";
import Sidebar from "../../components/Sidebar";
import styles from "../../styles/pages/mentalHealth/index.module.css";
import MentalHealthIcon from "../../assets/wellnessCommon/mentalHealthIcon.svg";
import MentalHealthAndSkinIcon from "../../assets/wellnessCommon/mentalHealth&SkinIcon.svg";
import ForwardArrow from "../../assets/wellnessCommon/arrow_forwardLogo.svg";
import MentalHealth from "./MentalHealth";
import MentalHealthAndSkin from "./MentalHealthAndSkin";
import miniFilterIcon from "../../assets/home/mini-filter-icon.svg";
import MultiFilter from "../../components/MultiFilter";

const MentalHealthPage = () => {
  const [activeTab, setActiveTab] = useState("mentalHealth");
  const [index, setIndex] = useState(0);
  const [page, setPage] = useState(1);
  const handleChange = (e) => {
    setIndex(Number(e) - 1);
  };

  const [selectedFilters, setSelectedFilters] = useState({
    ageFilter: [],
    genderFilter: [],
    skinTypeFilter: [],
    cityFilter: [],
  });

  const handleUpdateFilter = (filters) => {
    setSelectedFilters(filters);
  };

  const [open, setOpen] = useState(false);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const renderTabContent = () => {
    switch (activeTab) {
      case "mentalHealth":
        return <MentalHealth selectedFilters={selectedFilters}/>;
      case "mentalHealthAndskin":
        return <MentalHealthAndSkin selectedFilters={selectedFilters}/>;
      default:
        return null;
    }
  };

  return (
    <div className={styles.container}>
      <Sidebar handleChange={handleChange} />
      <div className={styles.bodyContainer}>
        <div className={styles.subBodyContainer}>
          <div className={styles.topBar}>
            <h2>Mental Health</h2>
            <img
              src={ForwardArrow}
              alt=""
              style={{ paddingBottom: "20px", marginLeft: "10px" }}
            />
            <div className={styles.tabContainer}>
              <div
                className={`${styles.subTabContainer} ${
                  activeTab === "mentalHealth" ? styles.buttonActive : ""
                }`}
                onClick={() => handleTabChange("mentalHealth")}
              >
                <img src={MentalHealthIcon} alt="Mental Health" />
                Mental Health
              </div>
              <div
                className={`${styles.subTabContainer} ${
                  activeTab === "mentalHealthAndskin" ? styles.buttonActive : ""
                }`}
                onClick={() => handleTabChange("mentalHealthAndskin")}
              >
                <img
                  src={MentalHealthAndSkinIcon}
                  alt="Mental Health And Skin"
                />
                Mental health & skin
              </div>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
              }}
              onClick={showDrawer}
            >
              <img src={filterIcon} height={35} alt="" />
            </div>
            <Drawer
              placement="right"
              width="auto"
              onClose={onClose}
              open={open}
              extra={
                <Space>
                  <div style={{ display: "flex", gap: "8px" }}>
                    <img src={miniFilterIcon} alt="" />
                    <p className={styles.filterTitle}>Filter</p>
                  </div>
                </Space>
              }
            >
              <MultiFilter handleUpdateFilter={handleUpdateFilter} />
            </Drawer>
          </div>
          <div style={{ display: "flex", gap: "10px", marginBottom: "20px" }}>
            {renderTabContent()}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MentalHealthPage;
