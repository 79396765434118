import { useState, useEffect } from "react";
import Section from "../../components/BrandComparisonSection";
import styles from "../../styles/pages/menstrualCare/habits.module.css";
import SemiChart from "../../components/SemiDouughnutChart";
import genderIcon from "../../assets/home/gender-icon.svg";
import chartIcon from "../../assets/home/chart-icon.svg";
import horizontalChartIcon from "../../assets/menstruationHabits/horizontalChartIcon.svg";
import HorizontalBarChart from "../../components/horizontalBarChart";
import SimpleBarChart from "../../components/BarChart";
import CompareBarChart from "../../components/CompareBarChart";
import fetchSurveyData from "../../services/index";
import noDataFound from "../../assets/home/ProductsNoDataFound.svg"

const menstrualCareData = require("../../data/menstrualCare.json");

const MenstrualHabits = ({selectedFilters}) => {
  const [sleepTroubleData, setSleepTroubleData] = useState([]);
  const [averageSleepData, setAverageSleepData] = useState([]);
  const [waterData, setWaterData] = useState([]);
  const [foodIntakeData, setFoodIntakeData] = useState([]);
  const [exerciseData, setExerciseData] = useState([]);
  const [periodsStressData, setperiodsStressData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const responseData = await fetchSurveyData("YaxRlAet", selectedFilters);
        const processSleepTroubleData = () => {
          const sleepTruobleCounts = {};
          responseData.forEach((entry) => {
            const { trouble_sleep } = entry;
            if (trouble_sleep) {
              if (sleepTruobleCounts[trouble_sleep]) {
                sleepTruobleCounts[trouble_sleep]++;
              } else {
                sleepTruobleCounts[trouble_sleep] = 1;
              }
            }
          });
          const sleepTroubleArray = Object.entries(sleepTruobleCounts).map(
            ([name, value]) => ({
              name,
              value,
              fill: name === "Yes" ? "#747ED4" : "#3F3F46",
            })
          );

          const sortedSleepTroubleArrayArray = sleepTroubleArray.sort(
            (a, b) => b.value - a.value
          );
          setSleepTroubleData(sortedSleepTroubleArrayArray);
        };

        const processAverageSleepData = () => {
          const averageSleepCounts = {};
          responseData.forEach((entry) => {
            const { average_sleep } = entry;
            if (average_sleep && average_sleep !== "") {
              const spendValues = average_sleep.split(/,\s*(?![^()]*\))/);
              spendValues.forEach((value) => {
                const trimmedValue = value.trim();
                if (trimmedValue !== "") {
                  if (averageSleepCounts[trimmedValue]) {
                    averageSleepCounts[trimmedValue]++;
                  } else {
                    averageSleepCounts[trimmedValue] = 1;
                  }
                }
              });
            }
          });

          const averageSleepArray = Object.entries(averageSleepCounts)
            .map(([name, value]) => ({ name: name || "Unknown", value }))
            .filter((entry) => entry.name !== "");

          const sortedAverageSleepArray = averageSleepArray
            .sort((a, b) => b.value - a.value)
            .slice(0, 4);

          setAverageSleepData(sortedAverageSleepArray);
        };

        const processWaterData = () => {
          const waterCounts = {};
          responseData.forEach((entry) => {
            const { water_consumption } = entry;
            if (water_consumption && water_consumption !== "") {
              const spendValues = water_consumption.split(/,\s*(?![^()]*\))/);
              spendValues.forEach((value) => {
                const trimmedValue = value.trim();
                if (trimmedValue !== "") {
                  if (waterCounts[trimmedValue]) {
                    waterCounts[trimmedValue]++;
                  } else {
                    waterCounts[trimmedValue] = 1;
                  }
                }
              });
            }
          });

          const waterArray = Object.entries(waterCounts)
            .map(([name, value]) => ({ name: name || "Unknown", value }))
            .filter((entry) => entry.name !== "");

          const sortedWaterArray = waterArray
            .sort((a, b) => b.value - a.value)
            .slice(0, 4);

          setWaterData(sortedWaterArray);
        };

        const processFoodIntakeData = () => {
          const foodIntakeCounts = {};
          responseData.forEach((entry) => {
            const { foods_intake } = entry;
            if (foods_intake && foods_intake !== "") {
              const spendValues = foods_intake.split(/,\s*(?![^()]*\))/);
              spendValues.forEach((value) => {
                const trimmedValue = value.trim();
                if (trimmedValue !== "") {
                  if (foodIntakeCounts[trimmedValue]) {
                    foodIntakeCounts[trimmedValue]++;
                  } else {
                    foodIntakeCounts[trimmedValue] = 1;
                  }
                }
              });
            }
          });

          const foodIntakeArray = Object.entries(foodIntakeCounts)
            .map(([name, value]) => ({ name: name || "Unknown", value }))
            .filter((entry) => entry.name !== "");

          const sortedFoodIntakeArray = foodIntakeArray
            .sort((a, b) => b.value - a.value)
            .slice(0, 4);

          setFoodIntakeData(sortedFoodIntakeArray);
        };

        const processExerciseData = () => {
          const exerciseCounts = {};
          responseData.forEach((entry) => {
            const { exercise_frequency } = entry;
            if (exercise_frequency && exercise_frequency !== "") {
              const spendValues = exercise_frequency.split(/,\s*(?![^()]*\))/);
              spendValues.forEach((value) => {
                const trimmedValue = value.trim();
                if (trimmedValue !== "") {
                  if (exerciseCounts[trimmedValue]) {
                    exerciseCounts[trimmedValue]++;
                  } else {
                    exerciseCounts[trimmedValue] = 1;
                  }
                }
              });
            }
          });

          const exerciseArray = Object.entries(exerciseCounts)
            .map(([name, value]) => ({ name: name || "Unknown", value }))
            .filter((entry) => entry.name !== "");

          const sortedExerciseArray = exerciseArray
            .sort((a, b) => b.value - a.value)
            .slice(0, 4);

          setExerciseData(sortedExerciseArray);
        };

        const processStressData = () => {
          const stressCounts = {};
          responseData.forEach((entry) => {
            const { period_stress } = entry;
            if (period_stress && period_stress !== "") {
              const spendValues = period_stress.split(/,\s*(?![^()]*\))/);
              spendValues.forEach((value) => {
                const trimmedValue = value.trim();
                if (trimmedValue !== "") {
                  if (stressCounts[trimmedValue]) {
                    stressCounts[trimmedValue]++;
                  } else {
                    stressCounts[trimmedValue] = 1;
                  }
                }
              });
            }
          });

          const stressArray = Object.entries(stressCounts)
            .map(([name, value]) => ({ name: name || "Unknown", value }))
            .filter((entry) => entry.name !== "");

          const sortedStressArray = stressArray
            .sort((a, b) => b.value - a.value)
            .slice(0, 4);

          setperiodsStressData(sortedStressArray);
        };

        processAverageSleepData();
        processSleepTroubleData();
        processWaterData();
        processFoodIntakeData();
        processExerciseData();
        processStressData();
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [selectedFilters]);

  return (
    <div style={{ width: "100%" }}>
      <div className={styles.brandsSelectContainer}>
        <Section
          width={33}
          heading={
            <div className={styles.heading}>
              <img src={genderIcon} alt="" />
              <p>
                Do you have trouble falling asleep right before or during your
                period?
              </p>
            </div>
          }
          content={
            sleepTroubleData.length > 0 ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  paddingBottom: "10px",
                  marginTop: "20px",
                }}
              >
                <SemiChart data={sleepTroubleData} label="Women 99%" />
                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    gap: "30px",
                  }}
                >
                  {sleepTroubleData.map((item, index) => (
                    <div
                      key={index}
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "10px",
                        justifyContent: "center",
                        alignItems: "center",
                        marginTop: "30px",
                      }}
                    >
                      <div
                        style={{
                          height: "16px",
                          width: "16px",
                          borderRadius: "50%",
                          backgroundColor: `${item.fill}`,
                        }}
                      ></div>
                      <div>{item.title || item.name}</div>
                    </div>
                  ))}
                </div>
              </div>
            ) : (
              <div><img src={noDataFound} alt=""  style={{marginLeft: "20px"}} /></div>
            )
          }
        />

        <Section
          width={33}
          heading={
            <div className={styles.heading}>
              <img src={chartIcon} alt="" />
              <p>
                How many hours of sleep do you get on average right before or
                during your period?
              </p>
            </div>
          }
          content={
            averageSleepData.length > 0 ? (
              <SimpleBarChart data={averageSleepData} />
            ) : (
              <div><img src={noDataFound} alt=""  style={{marginLeft: "20px"}} /></div>
            )
          }
        />

        <Section
          width={33}
          heading={
            <div className={styles.heading}>
              <img src={horizontalChartIcon} alt="" />
              <p>
                On average, how much water do you drink when you are on your
                period?
              </p>
            </div>
          }
          content={
            waterData.length > 0 ? (
              <HorizontalBarChart data={waterData} />
            ) : (
              <div><img src={noDataFound} alt=""  style={{marginLeft: "20px"}} /></div>
            )
          }
        />
      </div>

      <div className={styles.brandsSelectContainer}>
        <Section
          heading={
            <div className={styles.heading}>
              <img src={chartIcon} alt="" />
              <p>
                Which of the following foods do you tend to eat when you are on
                your period?
              </p>
            </div>
          }
          content={
            foodIntakeData.length > 0 ? (
              <CompareBarChart data={foodIntakeData}
              yAxisWidth={20}
              showLegend={false}
              slantXAxisLabels={true} />
            ) : (
              <div><img src={noDataFound} alt=""  style={{marginLeft: "20px"}} /></div>
            )
          }
          width={33}
        />

        <Section
          width={33}
          heading={
            <div className={styles.heading}>
              <img src={chartIcon} alt="" />
              <p>How often do you exercise when you are on your period?</p>
            </div>
          }
          content={
            exerciseData.length > 0 ? (
              <div style={{ display: "flex", flexDirection: "column" }}>
                {exerciseData.map((item, index) => (
                  <div
                    key={index}
                    style={{
                      height: "55px",
                      backgroundColor: calculateExerciseColor(item.value),
                      borderRadius: "8px",
                      marginTop: "5px",
                      marginBottom: "5px",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      padding: "0px 16px 0px 10px",
                    }}
                  >
                    <p style={{ fontWeight: "bold" }}>
                      {item.title || item.name}
                    </p>
                    <p
                      style={{
                        borderRadius: "100px",
                        backgroundColor: "#FFF",
                        padding: "5px",
                        border: "1px solid #747ED4",
                        textAlign: "center",
                        fontWeight: "bold",
                      }}
                    >
                      {item.value}
                    </p>
                  </div>
                ))}
              </div>
            ) : (
              <div><img src={noDataFound} alt=""  style={{marginLeft: "20px"}} /></div>
            )
          }
        />

        <Section
          width={33}
          heading={
            <div className={styles.heading}>
              <img src={chartIcon} alt="" />
              <p>
                Do you feel more stressed right before or during your period?
              </p>
            </div>
          }
          content={
            periodsStressData.length > 0 ? (
              <SimpleBarChart data={periodsStressData} />
            ) : (
              <div><img src={noDataFound} alt=""  style={{marginLeft: "20px"}} /></div>
            )
          }
        />
      </div>
    </div>
  );
};

const calculateExerciseColor = (count) => {
  if (count > 80) {
    return "#FBED1F";
  } else if (count > 10 && count <= 30) {
    return "#FFF565";
  } else if (count > 6 && count <= 10) {
    return "#FEFD8C";
  } else if (count > 0 && count <= 6) {
    return "#FBFFAE";
  } else {
    return "#FFFFFF";
  }
};

export default MenstrualHabits;
