import React from "react";

const FitnessIcon = ({ selected }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill={selected ? "#3F4E99" : "#71717A"}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M13.3616 3.53601C13.8456 3.62901 14.5526 3.87101 14.8936 4.55301C15.6406 6.04701 15.0236 7.46701 14.3616 8.35001C13.9296 8.92601 13.3736 9.46801 12.7266 9.80001C12.5916 9.87001 12.3166 10 11.9996 10C11.4086 10 10.8296 9.73101 10.3546 9.39501C10.2646 9.70101 10.1776 10.037 10.1026 10.393C9.95424 11.0761 9.87385 11.7721 9.86258 12.471C10.9156 12.131 11.9926 12.167 12.9206 12.691C13.6175 12.1425 14.4198 11.7433 15.2776 11.518C16.5776 11.177 18.1376 11.224 19.5546 12.168C20.5696 12.845 21.3386 14.12 21.4936 15.515C21.6556 16.969 21.1506 18.559 19.6236 19.781C18.5456 20.643 16.9026 20.976 15.3906 21.097C13.8256 21.223 12.1726 21.137 10.8896 20.994C8.51158 20.73 6.68558 20.066 5.40958 19.381C4.57258 18.931 3.42758 18.309 3.06458 17.356C2.81958 16.71 2.88358 15.926 2.94358 15.254C3.02358 14.373 3.21958 13.273 3.58658 12.081C4.31858 9.70201 5.75158 6.88701 8.36258 4.72901C9.16558 4.06501 10.5096 3.65301 11.6346 3.51501C12.2146 3.44401 12.8246 3.43401 13.3616 3.53601Z" />
  </svg>
);

export default FitnessIcon;
